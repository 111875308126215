import { Box, Typography } from '@mui/material'
import theme from 'theme'

interface Props {
  title: string
  subtitle?: string
  children?: React.ReactNode
}

const Title3 = ({ title, subtitle, children }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 2,
      }}
    >
      <Box>
        <Typography variant="h3">
          <strong>{title}</strong>
        </Typography>
        <Typography fontSize={14} color={theme.palette.grey[700]}>
          {subtitle}
        </Typography>
      </Box>
      {children}
    </Box>
  )
}

export default Title3
