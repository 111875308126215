import axios from 'axios'

import { tokenAxiosInstance } from 'services/apiClient'

const functionsBaseUrl = '/.netlify/functions/'

const functions = {
  getDataDiscoveryRuns: 'listDataDiscoveryRuns',
  getLastDataDiscoveryRun: 'getLastDataDiscoveryRun',
  getLastDataDiscoveryRunDataSetItems: 'getLastDataDiscoveryRunDataSetItems',
  getLastSupplierDiscoveryRunDataSetItems: 'getLastSupplierDiscoveryRunDataSetItems',
  runDataDiscovery: 'runDataDiscovery',
  runSupplierDiscovery: 'runSupplierDiscovery',
  websiteDiscovery: 'website-discovery',
  dataDiscovery: 'data-discovery',
  pushNotificationToSNS: 'pushNotificationToSNS',
  getFilesByOriginId: 'getFilesByOriginId',
  getDatadiscoveryRunsDdb: 'getDataDiscoveryRuns',
}

export interface TestDataDiscoveryProps {
  name: string
  id: string
  isDoNotMatchOnAcronymDataSource: boolean
  isCaseSensitiveBaseNamesDataSource: boolean
  isStandardMatchNeededDataSource: boolean
  isCheckForStandardInsteadOfBaseNameDataSource: boolean
  isSplitBaseNameUpAndLookForSecondWordAsStandardDataSource: boolean
  baseNames: string[]
  acronyms: string[]
  standards: string[]
  matches: {
    requestUrl: string
    generalSustainabilityKeywordMatches: string[]
    baseNameMatchingContexts: string[]
    acronymMatchingContexts: string[]
    standardMatchingContexts: string[]
  }[]
}

export interface DataDiscoveryRun {
  name: string
  country: string
  googleSearchUrl: string
  dataSourceMatches: {
    name: string
    id: string
    baseNames: string[]
    acronyms: string[]
    standards: string[]
    matchContexts: {
      url: string
      generalSustainabilityKeywordMatches: string[]
      baseNameMatchingContexts: string[]
      acronymMatchingContexts: string[]
      standardMatchingContexts: string[]
    }[]
    isDoNotMatchOnAcronymDataSource: boolean
    isCaseSensitiveBaseNamesDataSource: boolean
    isStandardMatchNeededDataSource: boolean
    isCheckForStandardInsteadOfBaseNameDataSource: boolean
    isSplitBaseNameUpAndLookForSecondWordAsStandardDataSource: boolean
  }[]
  emissionsKeywordMatches: {
    url: string
    matchContexts: {
      keyword: string
      matchingContexts: string[]
    }[]
  }[]
}

export interface LinkedinSearchResponse {
  logo: string
  name: string
  entityUrn: string
  url: string
  description?: string
  phone?: string
  tagline?: string
  websiteUrl?: string
  specialities?: string[]
  industries?: string[]
  industriesV2?: string[]
  employeeCount?: number
  employeeCountRange?: {
    start: number
    end: number
  }
  locations?: {
    address: {
      line1: string
      line2?: string
      line3?: string
      line4?: string
      city: string
      postalCode: string
      country: string
      geographicArea: string
    }
    description?: string
    headquarter?: boolean
  }[]
  foundedOn?: {
    year: number
  }
}

export interface WebsiteDiscoveryRun {
  url: string
  title: string
  description: string
  emphasizedKeywords: string[]
}

export interface SupplierDiscoveryRun {
  supplierName: string
  supplierCountry: string
  googleSearchWebsite?: string | undefined
  linkedinCompanyProfile?: LinkedinSearchResponse
}

export const getFilesByOriginId = async (originId: string) => {
  const { data } = await tokenAxiosInstance.get<{ count: number }>(
    `${functions.getFilesByOriginId}`,
    {
      baseURL: functionsBaseUrl,
      params: { originId },
    }
  )
  return data
}

export const getAdminDataDiscoveryRuns = async (domain: string) => {
  /*
    id: item.id?.S,
      domain: item.domain?.S,
      discoveries:
        'discoveries' in item && item.discoveries && 'L' in item.discoveries
          ? item.discoveries?.L?.map(({ M: discovery }) => {
              return {
                path: discovery?.path?.S,
                checksumSHA256: discovery?.checksumSHA256?.S ?? undefined,
                s3Key: discovery?.s3Key?.S ?? undefined,
              }
            })
          : [],
      startDate: item.startDate?.S,
      status: item.status?.S
  */
  const { data } = await tokenAxiosInstance.get<{
    items: {
      id: string
      domain: string
      discoveries: { path: string; checksumSHA256: string; s3Key: string }[]
      startDate: string
      status: string
    }[]
  }>(`${functions.getDatadiscoveryRunsDdb}`, {
    baseURL: functionsBaseUrl,
    params: { domain },
  })
  return data
}

export const getDataDiscoveryRuns = async (accessToken: string) => {
  const { data } = await axios.get(
    `${functionsBaseUrl}${functions.getDataDiscoveryRuns}?accessToken=${accessToken}`
  )
  return data
}

export const getLastDataDiscoveryRun = async (accessToken: string) => {
  const { data } = await axios.get(
    `${functionsBaseUrl}${functions.getLastDataDiscoveryRun}?accessToken=${accessToken}`
  )
  return data
}

export const getLastDataDiscoveryRunDataSetItems = async (accessToken: string) => {
  const { data } = await axios.get<DataDiscoveryRun[]>(
    `${functionsBaseUrl}${functions.getLastDataDiscoveryRunDataSetItems}?accessToken=${accessToken}`
  )
  return data
}

export const getLastSupplierDiscoveryRunDataSetItems = async (accessToken: string) => {
  const { data } = await axios.get<SupplierDiscoveryRun[]>(
    `${functionsBaseUrl}${functions.getLastSupplierDiscoveryRunDataSetItems}?accessToken=${accessToken}`
  )
  return data
}

export const runDataDiscovery = async (accessToken: string) => {
  const { data } = await axios.post(
    `${functionsBaseUrl}${functions.runDataDiscovery}?accessToken=${accessToken}`
  )
  return data
}

export const runSupplierDiscovery = async (accessToken: string) => {
  const { data } = await axios.post(
    `${functionsBaseUrl}${functions.runSupplierDiscovery}?accessToken=${accessToken}`
  )
  return data
}

export const runWebsiteDiscovery = async (supplierName: string, accessToken: string) => {
  const { data } = await axios.post<WebsiteDiscoveryRun[]>(
    `${functionsBaseUrl}${functions.websiteDiscovery}?supplierName=${supplierName}&accessToken=${accessToken}`
  )
  return data
}

export const runSingleDataDiscovery = async (website: string, accessToken: string) => {
  const { data } = await axios.post<any[]>(
    `${functionsBaseUrl}${functions.dataDiscovery}?website=${website}&accessToken=${accessToken}`
  )
  return data
}

export const testDataDiscovery = async (website: string) => {
  const { data } = await axios.post<TestDataDiscoveryProps[]>(
    'https://api.apify.com/v2/acts/responsibly~dd-test-christian/run-sync-get-dataset-items?token=apify_api_mw5dGceAG61910dgRxfezaam8Id3Br01pacV',
    {
      startUrl: website,
    },
    {
      timeout: 300000,
    }
  )
  return data
}

// const response = {
//   additionalInfo: { Accessibility: [{ 'Wheelchair-accessible entrance': true }] },
//   address: 'Nordborgvej 81, 6430 Nordborg, Denmark',
//   categories: ['Corporate office', 'Manufacturer'],
//   categoryName: 'Corporate office',
//   cid: '13037722753249335245',
//   city: 'Nordborg',
//   claimThisBusiness: false,
//   countryCode: 'DK',
//   description: null,
//   imagesCount: 42,
//   isAdvertisement: false,
//   locatedIn: null,
//   location: { lat: 55.034839, lng: 9.8147929 },
//   menu: null,
//   neighborhood: 'Nordborgvej 81',
//   openingHours: [
//     { day: 'Monday', hours: '8AM to 4PM' },
//     { day: 'Tuesday', hours: '8AM to 4PM' },
//   ],
//   orderBy: [],
//   peopleAlsoSearch: [
//     { category: 'People also search for', title: 'Danfoss L35', reviewsCount: 10, totalScore: 3.9 },
//   ],
//   permanentlyClosed: false,
//   phone: '+45 74 88 22 22',
//   placeId: 'ChIJby-AvO41s0cRzVd0a8RL77Q',
//   placesTags: [{ title: 'Other', count: 2 }],
//   plusCode: '2RM7+WW Nordborg, Denmark',
//   postalCode: '6430',
//   price: null,
//   rank: 1,
//   reviews: [],
//   reviewsCount: 58,
//   reviewsDistribution: { oneStar: 3, twoStar: 0, threeStar: 2, fourStar: 9, fiveStar: 44 },
//   scrapedAt: '2023-04-14T12:22:00.734Z',
//   searchPageLoadedUrl: 'https://www.google.com/maps/search/danfoss%20denmark?hl=en',
//   searchPageUrl: 'https://www.google.com/maps/search/danfoss%20denmark?hl=en',
//   searchString: 'danfoss denmark',
//   state: null,
//   street: 'Nordborgvej 81',
//   subTitle: null,
//   temporarilyClosed: false,
//   title: 'Danfoss A/S',
//   totalScore: 4.6,
//   url: 'https://www.google.com/maps/place/Danfoss+A%2FS/@55.034839,9.8147929,17z/data=!3m1!4b1!4m6!3m5!1s0x47b335eebc802f6f:0xb4ef4bc46b7457cd!8m2!3d55.034839!4d9.8147929!16s%2Fg%2F11fnw1h14d?hl=en',
//   webResults: [],
//   website: 'https://www.danfoss.com/',
// }

export interface IndustryDiscoveryProps {
  additionalInfo: {
    Accessibility: {
      [key: string]: boolean
    }[]
  }
  address: string
  categories: string[]
  categoryName: string
  cid: string
  city: string
  claimThisBusiness: boolean
  countryCode: string
  description: string | null
  imagesCount: number
  isAdvertisement: boolean
  locatedIn: string | null
  location: {
    lat: number
    lng: number
  }
  menu: string | null
  neighborhood: string
  openingHours: {
    day: string
    hours: string
  }[]
  orderBy: string[]
  peopleAlsoSearch: {
    category: string
    title: string
    reviewsCount: number
    totalScore: number
  }[]
  permanentlyClosed: boolean
  phone: string
  placeId: string
  placesTags: {
    title: string
    count: number
  }[]
  plusCode: string
  postalCode: string
  price: string | null
  rank: number
  reviews: string[]
  reviewsCount: number
  reviewsDistribution: {
    oneStar: number
    twoStar: number
    threeStar: number
    fourStar: number
    fiveStar: number
  }
  scrapedAt: string
  searchPageLoadedUrl: string
  searchPageUrl: string
  searchString: string
  state: string | null
  street: string
  subTitle: string | null
  temporarilyClosed: boolean
  title: string
  totalScore: number
  url: string
  webResults: string[]
  website: string
}

export const industryDiscovery = async (supplierName: string, supplierCountry: string) => {
  const { data } = await axios.post<IndustryDiscoveryProps[]>(
    'https://api.apify.com/v2/acts/compass~crawler-google-places/run-sync-get-dataset-items?token=apify_api_mw5dGceAG61910dgRxfezaam8Id3Br01pacV',
    {
      exportPlaceUrls: false,
      includeWebResults: false,
      maxCrawledPlacesPerSearch: 1,
      maxImages: 0,
      maxReviews: 0,
      oneReviewPerRow: false,
      proxyConfig: {
        useApifyProxy: true,
      },
      scrapeResponseFromOwnerText: true,
      scrapeReviewId: false,
      scrapeReviewUrl: false,
      scrapeReviewerId: false,
      scrapeReviewerName: false,
      scrapeReviewerUrl: false,
      searchStringsArray: [`${supplierName} ${supplierCountry}`],
    }
  )
  return data
}

export const pushNotificationToSNS = async ({
  snsTopicARN,
  title,
  description,
}: {
  snsTopicARN: string
  title: string
  description: string
}) => {
  const { data } = await axios.post(`${functionsBaseUrl}${functions.pushNotificationToSNS}`, {
    snsTopicARN,
    title,
    description,
  })
  return data
}
