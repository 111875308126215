export default (url?: string) => {
  if (!url || (url && url.includes('.') === false)) {
    return ''
  }

  try {
    const u = url.trim()
    const { hostname } = u.includes('://') ? new URL(u) : new URL(`http://${u}`)

    return hostname.startsWith('www.') ? hostname.slice(4) : hostname
  } catch (error) {
    return ''
  }
}
