import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query'
import {
  ResourceListRiskScoreWithMitigationsV2ApiDto,
  ResourceFoundRiskDataSourceApiDto,
  RiskDataSourceApiDto,
  ResourceListRiskDataEntryApiDto,
  ResourceListRiskObjectiveApiDto,
  ResourceListPolicyParameterRiskOverViewApiDto,
  ResourceFoundRiskMitigationRuleApiDto,
  PolicyParameterRiskOverViewFilterIn,
  ResourceListRiskMitigationRuleApiDto,
  RiskFilterIn,
  ResourceListRiskContextRiskOverviewApiDto,
  ResourceFoundGroupedRisksResponseApiDto,
  RiskContextRiskOverviewFilterIn,
} from 'typescript-axios'
import { AxiosError } from 'axios'
import staticMetaData, { DataSourceStaticMetadata } from '../dataSources/staticMetaData'
import {
  getSupplierRisk,
  getRiskDataEntries,
  getRiskDataSource,
  getRiskMitigationRule,
  getRiskMitigationRulesForParameters,
  getRiskObjective,
  getRisk,
  getOverviewRiskContext,
  getOverviewRiskParameters,
} from './api'
import queryKeys from './queryKeys'

const ROWS_PER_PAGE = 15
const {
  supplierRiskPage,
  singleRiskDataSource,
  riskDataEntriesPage,
  mitigationRulesPage,
  singleMitigationRule,
  riskObjective,
  riskGrouped,
  riskOverviewContext,
  riskOverviewParameters,
} = queryKeys

export interface ExtendedRiskDataSourceProps
  extends RiskDataSourceApiDto,
    DataSourceStaticMetadata {}

export const useGetRiskDataSource = (
  id: string,
  options?: UseQueryOptions<
    ResourceFoundRiskDataSourceApiDto,
    AxiosError,
    ExtendedRiskDataSourceProps,
    ReturnType<typeof singleRiskDataSource>
  >
) => {
  return useQuery(singleRiskDataSource(id), () => getRiskDataSource({ id }), {
    ...options,
    select: (data) => {
      const findStaticMetadata = data.data.id in staticMetaData ? staticMetaData[data.data.id] : {}
      return {
        ...findStaticMetadata,
        ...data.data,
      }
    },
  })
}

export const useGetRiskDataEntries = ({
  ids,
  pageSize = ROWS_PER_PAGE,
  enabled,
}: {
  ids: string[]
  pageSize?: number
  enabled?: boolean
}) => {
  return useInfiniteQuery<
    ResourceListRiskDataEntryApiDto,
    AxiosError,
    ResourceListRiskDataEntryApiDto,
    ReturnType<typeof riskDataEntriesPage>
  >(
    riskDataEntriesPage({ ids, pageSize }),
    ({ pageParam = 1 }) => getRiskDataEntries({ ids, pageSize, page: pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      enabled,
    }
  )
}

export const useGetSupplierRisk = (
  supplierId: string,
  options?: UseQueryOptions<
    ResourceListRiskScoreWithMitigationsV2ApiDto,
    AxiosError,
    ResourceListRiskScoreWithMitigationsV2ApiDto,
    ReturnType<typeof supplierRiskPage>
  >
) => {
  return useQuery(supplierRiskPage({ supplierId }), () => getSupplierRisk({ supplierId }), {
    ...options,
  })
}

export const useGetParameterMitigationRules = ({
  parameterIds,
  pageSize = ROWS_PER_PAGE,
  enabled,
}: {
  parameterIds: string[]
  pageSize?: number
  enabled?: boolean
}) => {
  return useInfiniteQuery<
    ResourceListRiskMitigationRuleApiDto,
    AxiosError,
    ResourceListRiskMitigationRuleApiDto,
    ReturnType<typeof mitigationRulesPage>
  >(
    mitigationRulesPage({ parameterIds, pageSize }),
    ({ pageParam = 1 }) =>
      getRiskMitigationRulesForParameters({ parameterIds, page: pageParam, pageSize }),
    {
      getNextPageParam: (lastPage) => lastPage.paging.nextPage,
      enabled,
      retry: 1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )
}

export const useRiskMitigationRule = (
  id: string,
  options?: UseQueryOptions<
    ResourceFoundRiskMitigationRuleApiDto,
    AxiosError,
    ResourceFoundRiskMitigationRuleApiDto,
    ReturnType<typeof singleMitigationRule>
  >
) => {
  return useQuery(singleMitigationRule(id), () => getRiskMitigationRule({ id }), {
    ...options,
  })
}

export const useGetRiskObjective = (
  options?: UseQueryOptions<
    ResourceListRiskObjectiveApiDto,
    AxiosError,
    ResourceListRiskObjectiveApiDto,
    typeof riskObjective
  >
) => {
  return useQuery(riskObjective, () => getRiskObjective(), {
    ...options,
  })
}

export const useGetGroupedRisk = (
  filters: RiskFilterIn,
  options?: UseQueryOptions<
    ResourceFoundGroupedRisksResponseApiDto,
    AxiosError,
    ResourceFoundGroupedRisksResponseApiDto,
    ReturnType<typeof riskGrouped>
  >
) => {
  return useQuery(riskGrouped(filters), () => getRisk(filters), {
    ...options,
  })
}

export const useGetOverviewRiskContext = (
  filters: RiskContextRiskOverviewFilterIn,
  options?: UseQueryOptions<
    ResourceListRiskContextRiskOverviewApiDto,
    AxiosError,
    ResourceListRiskContextRiskOverviewApiDto,
    ReturnType<typeof riskOverviewContext>
  >
) => {
  return useQuery(riskOverviewContext(filters), () => getOverviewRiskContext(filters), {
    ...options,
  })
}

export const useGetOverviewRiskParameters = (
  filters: PolicyParameterRiskOverViewFilterIn,
  options?: UseQueryOptions<
    ResourceListPolicyParameterRiskOverViewApiDto,
    AxiosError,
    ResourceListPolicyParameterRiskOverViewApiDto,
    ReturnType<typeof riskOverviewParameters>
  >,
  uniqueQueryKey?: string
) => {
  return useQuery(
    riskOverviewParameters(filters, uniqueQueryKey),
    () => getOverviewRiskParameters(filters),
    {
      ...options,
    }
  )
}
