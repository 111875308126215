import { memo } from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import {
  ListItem,
  ListItemText,
  Typography,
  alpha,
  Box,
  Collapse,
  Grid,
  AvatarGroup,
} from '@mui/material'
import { ChevronRightRounded as ChevronRightIcon } from '@mui/icons-material'
import config from 'constants/config'
import staticDataSourceMetaData from 'hooks/dataSources/staticMetaData'
import { getParameterCategory } from 'utils'
import { Donut } from 'components'
import DataAvatar from 'views/buyer/EntityDialog/components/DataAvatar'
import ScorecardItemAvatar from '../ScorecardItemAvatar'
import { ItemProps } from '../../index'

const PREFIX = 'index'

const classes = {
  listItem: `${PREFIX}-listItem`,
  listItemChild: `${PREFIX}-listItemChild`,
  listItemText: `${PREFIX}-listItemText`,
  container: `${PREFIX}-container`,
  expandedContainer: `${PREFIX}-expandedContainer`,
  flex: `${PREFIX}-flex`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.listItem}`]: {
    paddingTop: 6,
    paddingBottom: 6,
    minHeight: 54,
    paddingLeft: theme.spacing(),
    position: 'relative',
  },

  [`& .${classes.listItemChild}`]: {
    paddingLeft: theme.spacing(5),
  },

  [`& .${classes.listItemText}`]: {
    margin: 0,
    zIndex: 1,
  },

  [`& .${classes.container}`]: {
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.04),
    },
  },

  [`& .${classes.expandedContainer}`]: {
    backgroundColor: alpha(theme.palette.common.black, 0.02),
  },

  [`& .${classes.flex}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: -1,
  },
}))

interface Props extends ItemProps {
  children?: React.ReactNode
}

const Item = ({
  handleOpenScorecardItem,
  handleToggleExpanded,
  item,
  isChild,
  children,
  isExpanded,
  id,
  context,
  parameterQuestions,
  dataEntries,
  unscoredNewsEvents,
}: Props) => {
  const handleClick = () => (children ? handleToggleExpanded(id) : handleOpenScorecardItem(item))

  const containerClassName = classNames([classes.container], {
    [classes.expandedContainer]: isExpanded,
  })

  const listItemClassName = classNames([classes.listItem], {
    [classes.listItemChild]: isChild,
  })

  const dataSource = ('dataSource' in item && item.dataSource) || undefined
  const parameter = ('policyParameter' in item && item.policyParameter) || undefined
  const group = ('items' in item && item) || undefined

  const parameterTopLevelCategory = (parameter && getParameterCategory(parameter)) || undefined

  const name = dataSource?.name || parameter?.name || group?.name || ''
  const secondary =
    (dataSource?.dataSourceType && `${dataSource?.dataSourceType} data source`) ||
    (parameterTopLevelCategory && `${parameterTopLevelCategory.name} parameter`) ||
    (group?.items && `${group.items.length} items`) ||
    ''
  const avatarType = (parameter && 'parameter') || dataSource?.dataSourceType || 'group'
  const logoUrl =
    (dataSource?.id &&
      dataSource.id in staticDataSourceMetaData &&
      staticDataSourceMetaData[dataSource.id].imgUrl) ||
    undefined

  const performanceScore = item.scoreAndCoverage.score.value

  const parameterQuestionsWithAnswers =
    (parameterQuestions &&
      parameterQuestions.length > 0 &&
      parameterQuestions.filter((x) => x.answers && x.answers.length > 0)) ||
    []

  const combinedData = [
    ...(dataEntries || []),
    ...(parameterQuestionsWithAnswers || []),
    ...(unscoredNewsEvents || []),
  ]

  return (
    <Root>
      <Box onClick={handleClick} className={containerClassName}>
        <Grid container>
          <Grid item xs={context === 'supplier' ? 6 : 8}>
            <ListItem className={listItemClassName}>
              <ScorecardItemAvatar
                logoUrl={
                  logoUrl ? `${config.largeMediaUrl}${logoUrl}?nf_resize=fit&h=24` : undefined
                }
                type={avatarType.toLowerCase()}
                category={parameterTopLevelCategory?.name || undefined}
                isExpanded={isExpanded}
              />
              <ListItemText
                className={classes.listItemText}
                disableTypography
                primary={
                  <Typography component="span">
                    <strong>{name}</strong>
                  </Typography>
                }
                secondary={
                  <Typography
                    component="span"
                    style={{ display: 'flex' }}
                    variant="body2"
                    color="textSecondary"
                  >
                    {secondary}
                  </Typography>
                }
              />
            </ListItem>
          </Grid>
          {(!context || context === 'supplier') && (
            <Grid item xs={3} className={classes.flex}>
              {combinedData.length > 0 && (
                <>
                  <AvatarGroup
                    max={3}
                    sx={{
                      '& .MuiAvatarGroup-avatar': {
                        display: 'none',
                      },
                    }}
                  >
                    {combinedData.slice(0, 5).map((x) => {
                      if ('definition' in x) {
                        return (
                          <DataAvatar
                            key={`${x.definition.id}-${x.answers?.[0]?.id}-${x.mapping.type}`}
                            id={x.definition.id}
                            type="questionAnswer"
                            question={x.definition.title}
                          />
                        )
                      }
                      if ('result' in x) {
                        return (
                          <DataAvatar
                            key={`${x.id}-${x.result.name}`}
                            id={x.id}
                            type="newsEvent"
                            newsEvent={x.result.name || undefined}
                          />
                        )
                      }
                      return (
                        <DataAvatar
                          key={x.id}
                          dataEntryId={x.id}
                          type={
                            (x.dataSourceId === '01j9v5p4m452fvkmyj2efyt7kv' && 'newsEvent') ||
                            (x.dataSourceId === '01j8f1ajw0efjv1tkkktzx7j5k' && 'questionAnswer') ||
                            'dataSource'
                          }
                          id={x.dataSourceId}
                        />
                      )
                    })}
                  </AvatarGroup>
                  {combinedData.length > 5 && (
                    <Typography variant="body2" color="textSecondary" sx={{ ml: 0.5 }}>
                      +{combinedData.length - 5}
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          )}
          <Grid
            item
            xs={context === 'supplier' ? 3 : 4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Donut value={performanceScore} marginLeft="0px" gaugeMarginTop={-12} />
            <ChevronRightIcon style={{ marginRight: 8, opacity: group ? 0 : 1 }} />
          </Grid>
        </Grid>
      </Box>
      <Collapse in={isExpanded}>
        <div>{children}</div>
      </Collapse>
    </Root>
  )
}

export default memo(Item)
