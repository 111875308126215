import {
  Box,
  IconButton,
  Typography,
  ListItemButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import {
  ArrowBackRounded as ArrowBackIcon,
  CloseRounded as CloseIcon,
  ChevronRightRounded as ChevronRightIcon,
} from '@mui/icons-material'

interface Props {
  titleLink?: () => void
  title: React.ReactNode
  icon?: React.ReactNode
  secondTitle?: React.ReactNode
  handleBack?: () => void
  handleClose?: () => void
  secondIcon?: React.ReactNode
  badge?: React.ReactNode
  secondBadge?: React.ReactNode
}

const DialogTitleWrapper = ({
  titleLink,
  title,
  icon,
  handleBack,
  secondTitle,
  handleClose,
  secondIcon,
  badge,
  secondBadge,
}: Props) => {
  const titleListItemContent = (
    <>
      {icon && (
        <ListItemAvatar sx={{ minWidth: 36, display: 'flex', alignItems: 'center' }}>
          {icon}
        </ListItemAvatar>
      )}
      <ListItemText
        sx={{ padding: 0, pr: 1, maxWidth: secondTitle ? 320 : 600 }}
        primary={
          <Typography variant="h5" noWrap>
            <strong>{title}</strong>
            {badge}
          </Typography>
        }
        disableTypography
      />
    </>
  )

  return (
    <Box
      sx={{
        p: 2,
        pb: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: '-3px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        {handleBack && (
          <IconButton
            onClick={() => handleBack()}
            sx={{
              ml: -1,
              mr: 1,
              color: 'common.black',
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          {titleLink ? (
            <ListItemButton
              onClick={titleLink}
              dense
              sx={{ p: 0, pl: 0.5, pr: 0.5, borderRadius: 1, minWidth: 'fit-content' }}
            >
              {titleListItemContent}
            </ListItemButton>
          ) : (
            <ListItem dense sx={{ padding: 0 }}>
              {titleListItemContent}
            </ListItem>
          )}
          {secondTitle && (
            <ListItem dense sx={{ padding: 0 }}>
              <ListItemAvatar sx={{ minWidth: 36, display: 'flex', alignItems: 'center' }}>
                <ChevronRightIcon sx={{ mr: 1 }} />
                {secondIcon}
              </ListItemAvatar>
              <ListItemText
                sx={{
                  padding: 0,
                  maxWidth: typeof title === 'string' && title.length >= 31 ? 270 : 320,
                }}
                primary={
                  <Typography variant="h5" noWrap>
                    <strong>{secondTitle}</strong>
                    {secondBadge}
                  </Typography>
                }
                disableTypography
              />
            </ListItem>
          )}
        </Box>
      </Box>
      {handleClose && (
        <IconButton
          onClick={handleClose}
          sx={{
            mr: -1,
            mt: '-2px',
            mb: '-2px',
            color: 'common.black',
          }}
        >
          <CloseIcon color="inherit" />
        </IconButton>
      )}
    </Box>
  )
}

export default DialogTitleWrapper
