import { useState } from 'react'
import {
  TextField,
  Checkbox,
  Typography,
  Autocomplete,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Drawer,
  Box,
  chipClasses,
  autocompleteClasses,
  outlinedInputClasses,
  Divider,
  InputAdornment,
} from '@mui/material'
import {
  useGetParameters,
  useGetCountries,
  useGetInfiniteCategories,
  useGetIndustries,
} from 'hooks'
import { FilterListRounded as FilterIcon } from '@mui/icons-material'
import {
  CountryApiDto,
  SupplierCategoryApiDto,
  GicsIndustryApiDto,
  SupplierStatus,
} from 'typescript-axios'
import { ConditionType } from 'views/buyer/Scorecards/helpers'
import { FilterProps } from './helpers'

interface Props {
  open: boolean
  handleClose: (newFilters?: FilterProps) => void
  initialFilters: FilterProps
  /** For some objectives, we want to limit the parameters that can be selected */
  selectableParameterIds?: string[]
  defaultCurrencyIso3Code?: string
}

const SupplierFilterDrawer = ({
  open,
  handleClose,
  initialFilters,
  selectableParameterIds,
  defaultCurrencyIso3Code,
}: Props) => {
  const [innerFilters, setInnerFilters] = useState(initialFilters)

  const { data: parametersData } = useGetParameters()
  const { data: countriesData } = useGetCountries()
  const { data: categoriesData } = useGetInfiniteCategories(250)
  const { data: industriesData } = useGetIndustries(undefined, undefined, true, true)

  const parameters = parametersData?.pages.flatMap((x) => x) || []
  const countries = countriesData?.data || []
  const categories = categoriesData?.pages.flatMap((x) => x.data || []) || []
  const industries = industriesData?.data || []

  const handleChangeFilters = (newFilters: Partial<FilterProps>) => {
    setInnerFilters({ ...innerFilters, ...newFilters })
  }

  const selectableParameters =
    selectableParameterIds && selectableParameterIds.length > 0
      ? parameters?.filter((x) => selectableParameterIds?.includes(x.id))
      : parameters

  const hasSelectedAllRiskSeverityParameters =
    innerFilters.parameterRiskSeverity?.parameters?.length === selectableParameters?.length
  const hasSelectedAllParameterScoreParameters =
    innerFilters.parameterScore?.parameters?.length === selectableParameters?.length

  const handleHandleClose = () => handleClose()

  return (
    <Drawer open={open} anchor="right" onClose={handleHandleClose} variant="temporary">
      <Box>
        <Box sx={{ p: 2, pb: 16 }}>
          <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
            <FilterIcon sx={{ mr: 1 }} />
            <strong>Filters</strong>
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              <strong>Filter by supplier country</strong>
            </Typography>
            <Autocomplete
              multiple
              onChange={(_event, newValue) => {
                handleChangeFilters({
                  supplierCountries: newValue.map((x) => x.iso3Code),
                })
              }}
              value={
                (innerFilters.supplierCountries
                  ?.map((x) => {
                    const findCountry = countries?.find((y) => x === y.iso3Code)
                    return findCountry
                  })
                  .filter((x) => x?.iso3Code) as CountryApiDto[] | undefined) || []
              }
              isOptionEqualToValue={(option, val) => option.iso3Code === val.iso3Code}
              options={countries || []}
              disableCloseOnSelect
              openOnFocus
              loading={!countries}
              getOptionLabel={(option) => option.commonName}
              sx={{
                [`& .${autocompleteClasses.option}`]: {
                  padding: 0,
                },
                [`& .${autocompleteClasses.tag}`]: {
                  margin: '2px',
                  marginLeft: 0,
                },
                [`& .${autocompleteClasses.inputRoot}`]: {
                  minHeight: 36,
                  paddingTop: '2px !important',
                  paddingBottom: '2px !important',
                  paddingRight: '36px !important',
                },
                [`& .${autocompleteClasses.input}`]: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: 'body2.fontSize',
                },
                [`& .${autocompleteClasses.popupIndicator}`]: {
                  marginTop: '3px',
                  marginRight: -0.5,
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
                [`& .${autocompleteClasses.clearIndicator}`]: {
                  padding: '2px',
                  marginTop: '3px',
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
              }}
              ChipProps={{
                sx: {
                  [`& .${chipClasses.root}`]: {
                    height: 20,
                    backgroundColor: 'backgroundDark',
                    color: 'text.primary',
                    '&:focus': {
                      backgroundColor: 'backgroundDark',
                    },
                  },
                  [`& .${chipClasses.deleteIcon}`]: {
                    color: 'text.primary',
                  },
                  [`& .${chipClasses.label}`]: {
                    paddingRight: '10px',
                  },
                },
                size: 'small',
              }}
              renderOption={(props, option, { selected }) => (
                <Box
                  {...props}
                  key={option.iso3Code}
                  component="li"
                  sx={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: 'body2.fontSize',
                    width: '100%',
                    paddingRight: 0.5,
                  }}
                >
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      sx={{ marginLeft: -1, marginRight: 0, marginTop: -1, marginBottom: -1 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.commonName}
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    innerFilters.supplierCountries && innerFilters.supplierCountries.length > 0
                      ? ''
                      : 'Select countries'
                  }
                />
              )}
            />
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              <strong>Filter by supplier category</strong>
            </Typography>
            <Autocomplete
              multiple
              onChange={(_event, newValue) => {
                handleChangeFilters({
                  supplierCategories: newValue.map((x) => x.id),
                })
              }}
              value={
                (innerFilters.supplierCategories
                  ?.map((x) => {
                    const findCategory = categories?.find((y) => x === y.id)
                    return findCategory
                  })
                  .filter((x) => x?.id) as SupplierCategoryApiDto[] | undefined) || []
              }
              isOptionEqualToValue={(option, val) => option.id === val.id}
              options={categories || []}
              disableCloseOnSelect
              openOnFocus
              loading={!categories}
              getOptionLabel={(option) => option.name}
              sx={{
                [`& .${autocompleteClasses.option}`]: {
                  padding: 0,
                },
                [`& .${autocompleteClasses.tag}`]: {
                  margin: '2px',
                  marginLeft: 0,
                },
                [`& .${autocompleteClasses.inputRoot}`]: {
                  minHeight: 36,
                  paddingTop: '2px !important',
                  paddingBottom: '2px !important',
                  paddingRight: '36px !important',
                },
                [`& .${autocompleteClasses.input}`]: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: 'body2.fontSize',
                },
                [`& .${autocompleteClasses.popupIndicator}`]: {
                  marginTop: '3px',
                  marginRight: -0.5,
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
                [`& .${autocompleteClasses.clearIndicator}`]: {
                  padding: '2px',
                  marginTop: '3px',
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
              }}
              ChipProps={{
                sx: {
                  [`& .${chipClasses.root}`]: {
                    height: 20,
                    backgroundColor: 'backgroundDark',
                    color: 'text.primary',
                    '&:focus': {
                      backgroundColor: 'backgroundDark',
                    },
                  },
                  [`& .${chipClasses.deleteIcon}`]: {
                    color: 'text.primary',
                  },
                  [`& .${chipClasses.label}`]: {
                    paddingRight: '10px',
                  },
                },
                size: 'small',
              }}
              renderOption={(props, option, { selected }) => {
                return (
                  <Box
                    {...props}
                    key={option.id}
                    component="li"
                    sx={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontSize: 'body2.fontSize',
                      width: '100%',
                      paddingRight: 0.5,
                    }}
                  >
                    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        sx={{ marginLeft: -1, marginRight: 0, marginTop: -1, marginBottom: -1 }}
                        checked={selected}
                        color="primary"
                      />
                      {option.name}
                    </Box>
                  </Box>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    innerFilters.supplierCategories && innerFilters.supplierCategories.length > 0
                      ? ''
                      : 'Select categories'
                  }
                />
              )}
            />
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              <strong>Filter by supplier industry</strong>
            </Typography>
            <Autocomplete
              multiple
              onChange={(_event, newValue) => {
                handleChangeFilters({
                  supplierIndustries: newValue.map((x) => x.code),
                })
              }}
              value={
                (innerFilters.supplierIndustries
                  ?.map((x) => {
                    const findIndustry = industries?.find((y) => x === y.code)
                    return findIndustry
                  })
                  .filter((x) => x?.code) as GicsIndustryApiDto[] | undefined) || []
              }
              isOptionEqualToValue={(option, val) => option.code === val.code}
              options={industries || []}
              disableCloseOnSelect
              openOnFocus
              loading={!categories}
              getOptionLabel={(option) => option.name}
              sx={{
                [`& .${autocompleteClasses.option}`]: {
                  padding: 0,
                },
                [`& .${autocompleteClasses.tag}`]: {
                  margin: '2px',
                  marginLeft: 0,
                },
                [`& .${autocompleteClasses.inputRoot}`]: {
                  minHeight: 36,
                  paddingTop: '2px !important',
                  paddingBottom: '2px !important',
                  paddingRight: '36px !important',
                },
                [`& .${autocompleteClasses.input}`]: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: 'body2.fontSize',
                },
                [`& .${autocompleteClasses.popupIndicator}`]: {
                  marginTop: '3px',
                  marginRight: -0.5,
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
                [`& .${autocompleteClasses.clearIndicator}`]: {
                  padding: '2px',
                  marginTop: '3px',
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
              }}
              ChipProps={{
                sx: {
                  [`& .${chipClasses.root}`]: {
                    height: 20,
                    backgroundColor: 'backgroundDark',
                    color: 'text.primary',
                    '&:focus': {
                      backgroundColor: 'backgroundDark',
                    },
                  },
                  [`& .${chipClasses.deleteIcon}`]: {
                    color: 'text.primary',
                  },
                  [`& .${chipClasses.label}`]: {
                    paddingRight: '10px',
                  },
                },
                size: 'small',
              }}
              renderOption={(props, option, { selected }) => {
                return (
                  <Box
                    {...props}
                    key={option.code}
                    component="li"
                    sx={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontSize: 'body2.fontSize',
                      width: '100%',
                      paddingRight: 0.5,
                    }}
                  >
                    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        sx={{ marginLeft: -1, marginRight: 0, marginTop: -1, marginBottom: -1 }}
                        checked={selected}
                        color="primary"
                      />
                      {option.name}
                    </Box>
                  </Box>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    innerFilters.supplierIndustries && innerFilters.supplierIndustries.length > 0
                      ? ''
                      : 'Select industries'
                  }
                />
              )}
            />
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              <strong>Filter by supplier status</strong>
            </Typography>
            <Autocomplete
              onChange={(_event, newValue) => {
                handleChangeFilters({
                  supplierStatus: newValue || undefined,
                })
              }}
              value={innerFilters.supplierStatus || null}
              options={Object.values(SupplierStatus)}
              getOptionLabel={(option) => option}
              sx={{
                [`& .${autocompleteClasses.option}`]: {
                  padding: 0,
                },
                [`& .${autocompleteClasses.tag}`]: {
                  margin: '2px',
                  marginLeft: 0,
                },
                [`& .${autocompleteClasses.inputRoot}`]: {
                  minHeight: 36,
                  paddingTop: '2px !important',
                  paddingBottom: '2px !important',
                  paddingRight: '36px !important',
                },
                [`& .${autocompleteClasses.input}`]: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: 'body2.fontSize',
                },
                [`& .${autocompleteClasses.popupIndicator}`]: {
                  marginTop: '3px',
                  marginRight: -0.5,
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
                [`& .${autocompleteClasses.clearIndicator}`]: {
                  padding: '2px',
                  marginTop: '3px',
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
              }}
              ChipProps={{
                sx: {
                  [`& .${chipClasses.root}`]: {
                    height: 20,
                    backgroundColor: 'backgroundDark',
                    color: 'text.primary',
                    '&:focus': {
                      backgroundColor: 'backgroundDark',
                    },
                  },
                  [`& .${chipClasses.deleteIcon}`]: {
                    color: 'text.primary',
                  },
                  [`& .${chipClasses.label}`]: {
                    paddingRight: '10px',
                  },
                },
                size: 'small',
              }}
              renderOption={(props, option) => (
                <Box
                  {...props}
                  key={option}
                  component="li"
                  sx={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: 'body2.fontSize',
                    width: '100%',
                    paddingRight: 0.5,
                  }}
                >
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                    {option}
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    innerFilters.supplierStatus && innerFilters.supplierStatus.length > 0
                      ? ''
                      : 'Select supplier status'
                  }
                />
              )}
            />
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              <strong>Filter by spend</strong>
            </Typography>
            <Autocomplete
              onChange={(_event, newValue) => {
                handleChangeFilters({
                  spend: {
                    ...innerFilters.spend,
                    condition: newValue
                      ? (newValue as
                          | ConditionType.GREATER_THAN_OR_EQUAL
                          | ConditionType.LESS_THAN_OR_EQUAL)
                      : undefined,
                  },
                })
              }}
              value={innerFilters.spend?.condition || null}
              isOptionEqualToValue={(option, val) => option === val}
              options={[ConditionType.GREATER_THAN_OR_EQUAL, ConditionType.LESS_THAN_OR_EQUAL]}
              getOptionLabel={(option) => option.toString()}
              sx={{
                mb: 1,
                [`& .${autocompleteClasses.option}`]: {
                  padding: 0,
                },
                [`& .${autocompleteClasses.tag}`]: {
                  margin: '2px',
                  marginLeft: 0,
                },
                [`& .${autocompleteClasses.inputRoot}`]: {
                  minHeight: 36,
                  paddingTop: '2px !important',
                  paddingBottom: '2px !important',
                  paddingRight: '36px !important',
                },
                [`& .${autocompleteClasses.input}`]: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: 'body2.fontSize',
                },
                [`& .${autocompleteClasses.popupIndicator}`]: {
                  marginTop: '3px',
                  marginRight: -0.5,
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
                [`& .${autocompleteClasses.clearIndicator}`]: {
                  padding: '2px',
                  marginTop: '3px',
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
              }}
              renderOption={(props, option) => (
                <Box
                  {...props}
                  key={option}
                  component="li"
                  sx={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: 'body2.fontSize',
                    width: '100%',
                    paddingRight: 0.5,
                  }}
                >
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                    {option === ConditionType.GREATER_THAN_OR_EQUAL
                      ? 'Greater than or equal to (>=)'
                      : 'Less than or equal to (<=)'}
                  </Box>
                </Box>
              )}
              renderInput={(params) => <TextField {...params} placeholder="Select condition" />}
            />
            <TextField
              placeholder="Enter spend value"
              fullWidth
              InputProps={{
                startAdornment: defaultCurrencyIso3Code ? (
                  <InputAdornment position="start">
                    <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '-2px' }}>
                      <strong>{defaultCurrencyIso3Code}</strong>
                    </Typography>
                  </InputAdornment>
                ) : undefined,
              }}
              onChange={(e) => {
                handleChangeFilters({
                  spend: {
                    ...innerFilters.spend,
                    value: e.target.value !== undefined ? Number(e.target.value) : undefined,
                  },
                })
              }}
              value={innerFilters.spend?.value || null}
              size="small"
              name="spendValue"
              type="number"
              sx={{
                mt: 1,
                [`& .${outlinedInputClasses.root}`]: {
                  fontSize: (theme) => theme.typography.body2.fontSize,
                },
              }}
            />
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              <strong>Filter by risk severity</strong>
            </Typography>
            <ToggleButtonGroup
              color="primary"
              size="small"
              fullWidth
              sx={{ height: 36 }}
              value={innerFilters.parameterRiskSeverity?.severity}
              onChange={(_event, newValue: ('low' | 'medium' | 'high')[]) => {
                handleChangeFilters({
                  parameterRiskSeverity:
                    newValue && newValue.length > 0
                      ? {
                          severity: newValue,
                          parameters: innerFilters.parameterRiskSeverity?.parameters || [],
                        }
                      : undefined,
                })
              }}
            >
              <ToggleButton
                value="low"
                sx={{ textTransform: 'none', fontSize: (theme) => theme.typography.body2.fontSize }}
              >
                <strong>Low</strong>
              </ToggleButton>
              <ToggleButton
                value="medium"
                sx={{ textTransform: 'none', fontSize: (theme) => theme.typography.body2.fontSize }}
              >
                <strong>Medium</strong>
              </ToggleButton>
              <ToggleButton
                value="high"
                sx={{ textTransform: 'none', fontSize: (theme) => theme.typography.body2.fontSize }}
              >
                <strong>High</strong>
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              On any of the following parameters
            </Typography>
            <Autocomplete
              multiple
              onChange={(_event, newValue) => {
                handleChangeFilters({
                  parameterRiskSeverity: {
                    severity: innerFilters.parameterRiskSeverity?.severity || [],
                    parameters: newValue.map((x) => x.id),
                  },
                })
              }}
              value={
                selectableParameters?.filter((x) =>
                  innerFilters.parameterRiskSeverity?.parameters?.includes(x.id)
                ) || []
              }
              isOptionEqualToValue={(option, val) => option.id === val.id}
              options={selectableParameters || []}
              disableCloseOnSelect
              openOnFocus
              limitTags={1}
              loading={!parametersData}
              getOptionLabel={(option) => option.name}
              sx={{
                [`& .${autocompleteClasses.option}`]: {
                  padding: 0,
                },
                [`& .${autocompleteClasses.tag}`]: {
                  margin: '2px',
                  marginLeft: 0,
                },
                [`& .${autocompleteClasses.inputRoot}`]: {
                  minHeight: 36,
                  paddingTop: '2px !important',
                  paddingBottom: '2px !important',
                  paddingRight: '36px !important',
                },
                [`& .${autocompleteClasses.input}`]: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: 'body2.fontSize',
                },
                [`& .${autocompleteClasses.popupIndicator}`]: {
                  marginTop: '3px',
                  marginRight: -0.5,
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
                [`& .${autocompleteClasses.clearIndicator}`]: {
                  padding: '2px',
                  marginTop: '3px',
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
              }}
              ChipProps={{
                sx: {
                  [`& .${chipClasses.root}`]: {
                    height: 20,
                    backgroundColor: 'backgroundDark',
                    color: 'text.primary',
                    '&:focus': {
                      backgroundColor: 'backgroundDark',
                    },
                  },
                  [`& .${chipClasses.deleteIcon}`]: {
                    color: 'text.primary',
                  },
                  [`& .${chipClasses.label}`]: {
                    paddingRight: '10px',
                  },
                },
                size: 'small',
              }}
              renderOption={(props, option, { selected }) => (
                <Box
                  {...props}
                  key={option.id}
                  component="li"
                  sx={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: 'body2.fontSize',
                    width: '100%',
                    paddingRight: 0.5,
                  }}
                >
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      sx={{ marginLeft: -1, marginRight: 0, marginTop: -1, marginBottom: -1 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.name}
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    innerFilters.parameterRiskSeverity?.parameters &&
                    innerFilters.parameterRiskSeverity?.parameters.length > 0
                      ? ''
                      : 'Select parameters'
                  }
                />
              )}
            />
            <Typography
              variant="body2"
              color="primary"
              sx={{ pt: 0.5, cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
              onClick={() => {
                handleChangeFilters({
                  parameterRiskSeverity: {
                    severity: innerFilters.parameterRiskSeverity?.severity || [],
                    parameters: hasSelectedAllRiskSeverityParameters
                      ? []
                      : selectableParameters?.map((x) => x.id) || [],
                  },
                })
              }}
            >
              {hasSelectedAllRiskSeverityParameters ? 'Deselect all' : 'Select all'}
            </Typography>
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              <strong>Filter by performance score</strong>
            </Typography>
            <Autocomplete
              onChange={(_event, newValue) => {
                handleChangeFilters({
                  parameterScore: {
                    ...innerFilters.parameterScore,
                    condition: newValue
                      ? (newValue as
                          | ConditionType.GREATER_THAN_OR_EQUAL
                          | ConditionType.LESS_THAN_OR_EQUAL)
                      : undefined,
                  },
                })
              }}
              value={innerFilters.parameterScore?.condition || null}
              isOptionEqualToValue={(option, val) => option === val}
              options={[ConditionType.GREATER_THAN_OR_EQUAL, ConditionType.LESS_THAN_OR_EQUAL]}
              limitTags={1}
              loading={!parametersData}
              getOptionLabel={(option) => option.toString()}
              sx={{
                mb: 1,
                [`& .${autocompleteClasses.option}`]: {
                  padding: 0,
                },
                [`& .${autocompleteClasses.tag}`]: {
                  margin: '2px',
                  marginLeft: 0,
                },
                [`& .${autocompleteClasses.inputRoot}`]: {
                  minHeight: 36,
                  paddingTop: '2px !important',
                  paddingBottom: '2px !important',
                  paddingRight: '36px !important',
                },
                [`& .${autocompleteClasses.input}`]: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: 'body2.fontSize',
                },
                [`& .${autocompleteClasses.popupIndicator}`]: {
                  marginTop: '3px',
                  marginRight: -0.5,
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
                [`& .${autocompleteClasses.clearIndicator}`]: {
                  padding: '2px',
                  marginTop: '3px',
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
              }}
              renderOption={(props, option) => (
                <Box
                  {...props}
                  key={option}
                  component="li"
                  sx={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: 'body2.fontSize',
                    width: '100%',
                    paddingRight: 0.5,
                  }}
                >
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                    {option === ConditionType.GREATER_THAN_OR_EQUAL
                      ? 'Greater than or equal to (>=)'
                      : 'Less than or equal to (<=)'}
                  </Box>
                </Box>
              )}
              renderInput={(params) => <TextField {...params} placeholder="Select condition" />}
            />
            <Autocomplete
              onChange={(_event, newValue) => {
                handleChangeFilters({
                  parameterScore: {
                    ...innerFilters.parameterScore,
                    value:
                      newValue === null
                        ? undefined
                        : (newValue as 0 | 2 | 1 | -1 | 3 | -3 | -2 | undefined),
                  },
                })
              }}
              value={innerFilters.parameterScore?.value || null}
              isOptionEqualToValue={(option, val) => option === val}
              options={[3, 2, 1, 0, -1, -2, -3]}
              loading={!parametersData}
              getOptionLabel={(option) => option.toString()}
              sx={{
                [`& .${autocompleteClasses.option}`]: {
                  padding: 0,
                },
                [`& .${autocompleteClasses.tag}`]: {
                  margin: '2px',
                  marginLeft: 0,
                },
                [`& .${autocompleteClasses.inputRoot}`]: {
                  minHeight: 36,
                  paddingTop: '2px !important',
                  paddingBottom: '2px !important',
                  paddingRight: '36px !important',
                },
                [`& .${autocompleteClasses.input}`]: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: 'body2.fontSize',
                },
                [`& .${autocompleteClasses.popupIndicator}`]: {
                  marginTop: '3px',
                  marginRight: -0.5,
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
                [`& .${autocompleteClasses.clearIndicator}`]: {
                  padding: '2px',
                  marginTop: '3px',
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
              }}
              renderOption={(props, option) => (
                <Box
                  {...props}
                  key={option}
                  component="li"
                  sx={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: 'body2.fontSize',
                    width: '100%',
                    paddingRight: 0.5,
                  }}
                >
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                    {option}
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select performance score" />
              )}
            />
            <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              On any of the following parameters
            </Typography>
            <Autocomplete
              multiple
              onChange={(_event, newValue) => {
                handleChangeFilters({
                  parameterScore: {
                    ...innerFilters.parameterScore,
                    parameters: newValue.map((x) => x.id),
                  },
                })
              }}
              value={
                selectableParameters?.filter((x) =>
                  innerFilters.parameterScore?.parameters?.includes(x.id)
                ) || []
              }
              isOptionEqualToValue={(option, val) => option.id === val.id}
              options={selectableParameters || []}
              disableCloseOnSelect
              openOnFocus
              limitTags={1}
              loading={!parametersData}
              getOptionLabel={(option) => option.name}
              sx={{
                [`& .${autocompleteClasses.option}`]: {
                  padding: 0,
                },
                [`& .${autocompleteClasses.tag}`]: {
                  margin: '2px',
                  marginLeft: 0,
                },
                [`& .${autocompleteClasses.inputRoot}`]: {
                  minHeight: 36,
                  paddingTop: '2px !important',
                  paddingBottom: '2px !important',
                  paddingRight: '36px !important',
                },
                [`& .${autocompleteClasses.input}`]: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: 'body2.fontSize',
                },
                [`& .${autocompleteClasses.popupIndicator}`]: {
                  marginTop: '3px',
                  marginRight: -0.5,
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
                [`& .${autocompleteClasses.clearIndicator}`]: {
                  padding: '2px',
                  marginTop: '3px',
                  '& svg': {
                    width: 18,
                    height: 18,
                  },
                },
              }}
              ChipProps={{
                sx: {
                  [`& .${chipClasses.root}`]: {
                    height: 20,
                    backgroundColor: 'backgroundDark',
                    color: 'text.primary',
                    '&:focus': {
                      backgroundColor: 'backgroundDark',
                    },
                  },
                  [`& .${chipClasses.deleteIcon}`]: {
                    color: 'text.primary',
                  },
                  [`& .${chipClasses.label}`]: {
                    paddingRight: '10px',
                  },
                },
                size: 'small',
              }}
              renderOption={(props, option, { selected }) => (
                <Box
                  {...props}
                  key={option.id}
                  component="li"
                  sx={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: 'body2.fontSize',
                    width: '100%',
                    paddingRight: 0.5,
                  }}
                >
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      sx={{ marginLeft: -1, marginRight: 0, marginTop: -1, marginBottom: -1 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.name}
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    innerFilters.parameterScore?.parameters &&
                    innerFilters.parameterScore?.parameters.length > 0
                      ? ''
                      : 'Select parameters'
                  }
                />
              )}
            />
            <Typography
              variant="body2"
              color="primary"
              sx={{ pt: 0.5, cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
              onClick={() => {
                handleChangeFilters({
                  parameterScore: {
                    ...innerFilters.parameterScore,
                    parameters: hasSelectedAllParameterScoreParameters
                      ? []
                      : selectableParameters?.map((x) => x.id) || [],
                  },
                })
              }}
            >
              {hasSelectedAllParameterScoreParameters ? 'Deselect all' : 'Select all'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          maxWidth: 283,
          width: '100%',
          backgroundColor: 'common.white',
          padding: 2,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button fullWidth variant="text" onClick={() => handleClose()}>
          Close
        </Button>
        <Button
          fullWidth
          onClick={() => {
            handleClose({
              ...innerFilters,
              parameterRiskSeverity:
                innerFilters.parameterRiskSeverity?.severity &&
                innerFilters.parameterRiskSeverity?.severity?.length > 0 &&
                innerFilters.parameterRiskSeverity?.parameters &&
                innerFilters.parameterRiskSeverity?.parameters?.length > 0
                  ? innerFilters.parameterRiskSeverity
                  : undefined,
              parameterScore:
                innerFilters.parameterScore?.value !== undefined &&
                innerFilters.parameterScore?.parameters &&
                innerFilters.parameterScore?.parameters?.length > 0 &&
                innerFilters.parameterScore.condition
                  ? innerFilters.parameterScore
                  : undefined,
              spend:
                innerFilters.spend?.condition && innerFilters.spend?.value !== undefined
                  ? innerFilters.spend
                  : undefined,
            })
          }}
        >
          Apply filters
        </Button>
      </Box>
    </Drawer>
  )
}

export default SupplierFilterDrawer
