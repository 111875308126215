export interface Config {
  api: string
  mingle: string
  /** auth0Domain */
  a0Domain: string
  /** auth0ClientId */
  a0ClientId: string
  /** auth0Audience */
  a0Audience: string
  /** sentryDsn */
  sDsn: string
  /** sentryProxyUrl */
  spu: string
  /** mixpanelProjectToken */
  mpt: string
  /** mixpanelProxyUrl */
  mpu: string
  /** largeMediaUrl */
  lmu: string
  /** toc url */
  toc: string
  /** marketplace api documentation url */
  apiDocs: string
  commit_ref: string
}

declare global {
  interface Window {
    config?: Config
  }
}

const config = {
  apiBaseUrl: window.config?.api || '',
  mingleBaseUrl: window.config?.mingle || '',
  auth0Domain: window.config?.a0Domain || '',
  auth0ClientId: window.config?.a0ClientId || '',
  auth0Audience: window.config?.a0Audience || '',
  sentryDsn: window.config?.sDsn || '',
  sentryProxyUrl: window.config?.spu || '',
  mixpanelProjectToken: window.config?.mpt || '',
  mixpanelProxyUrl: window.config?.mpu || '',
  largeMediaUrl: window.config?.lmu || '',
  termsAndConditionsUrl: window.config?.toc || '',
  commitRef: window.config?.commit_ref || '',
  apiDocumentationUrl:
    'https://marketplace-api.responsibly.tech/docs/spec3.yaml?version=2024-10-01',
}

export default config
