import { DataFeedItemAdverseMediaV2 } from 'services/mingle/__generated__/Api'

export default (newsEventArticles?: DataFeedItemAdverseMediaV2[]) => {
  let earliestNewsEventArticleDate = newsEventArticles?.reduce((prev, curr) => {
    if (!prev) return curr.article.publishedAt || undefined
    if (prev && curr.article.publishedAt)
      return new Date(prev) < new Date(curr.article.publishedAt) ? prev : curr.article.publishedAt
    return prev
  }, undefined as string | undefined)

  let latestNewsEventArticleDate = newsEventArticles?.reduce((prev, curr) => {
    if (!prev) return curr.article.publishedAt || undefined
    if (prev && curr.article.publishedAt)
      return new Date(prev) > new Date(curr.article.publishedAt) ? prev : curr.article.publishedAt
    return prev
  }, undefined as string | undefined)

  // Set time to 00:00:00 for consistent date comparison
  if (earliestNewsEventArticleDate) {
    const date = new Date(earliestNewsEventArticleDate)
    date.setHours(0, 0, 0, 0)
    earliestNewsEventArticleDate = date.toISOString()
  }

  if (latestNewsEventArticleDate) {
    const date = new Date(latestNewsEventArticleDate)
    date.setHours(0, 0, 0, 0)
    latestNewsEventArticleDate = date.toISOString()
  }
  return { earliestNewsEventArticleDate, latestNewsEventArticleDate }
}
