import { useQuery, UseQueryOptions } from 'react-query'
import {
  ResourceFoundUserApiDto,
  ResourceFoundOrganizationApiDto,
  CurrencyApiDto,
  ResourceListCurrencyApiDto,
} from 'typescript-axios'
import { AxiosError } from 'axios'
import { getMe, getSettings, getCurrencies } from './api'
import queryKeys from './queryKeys'

export const useGetMe = (
  options?: UseQueryOptions<
    ResourceFoundUserApiDto,
    AxiosError,
    ResourceFoundUserApiDto,
    typeof queryKeys.me
  >
) => {
  return useQuery(queryKeys.me, () => getMe(), {
    staleTime: Infinity,
    ...options,
  })
}

export const useGetSettings = (
  options?: UseQueryOptions<
    ResourceFoundOrganizationApiDto,
    AxiosError,
    ResourceFoundOrganizationApiDto,
    typeof queryKeys.settings
  >
) => {
  return useQuery(queryKeys.settings, () => getSettings(), {
    staleTime: Infinity,
    ...options,
  })
}

export const useGetCurrencies = (
  options?: UseQueryOptions<
    ResourceListCurrencyApiDto,
    AxiosError,
    ResourceListCurrencyApiDto,
    typeof queryKeys.currencies
  >
) => {
  return useQuery(queryKeys.currencies, () => getCurrencies(), {
    staleTime: Infinity,
    ...options,
  })
}
