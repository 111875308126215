import { useMutation, useQueryClient } from 'react-query'
import {
  createDataSubscription,
  deleteDataSubscription,
  createDataFeedSupplierAliasItem,
  updateDataFeedItemStatus,
  scoreDataRequestAnswers,
  deleteDataRequestAnswerScores,
  updateDataRequestAnswerScores,
  postNewsAndMediaEventScores,
} from './api'
import queryKeys from './queryKeys'

// Get entry ids from Scorecard
// Fetch data feed items by entry ids
// If you exclude articles and there's 0 articles left, remove the data entry

/** Empty array of parameter scores will remove the data entry on the supplier, i.e. become unscored */
export const useUpdateNewsAndMediaEventScores = () => {
  return useMutation(postNewsAndMediaEventScores)
}

export const useDeleteDataSubscription = (supplierId?: string) => {
  const queryClient = useQueryClient()

  return useMutation(deleteDataSubscription, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.dataSubscriptions(supplierId))
    },
  })
}

export const useCreateDataSubscription = (supplierId?: string) => {
  const queryClient = useQueryClient()

  return useMutation(createDataSubscription, {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.dataSubscriptions(supplierId))
    },
  })
}

export const useCreateDataFeedSupplierAliasItem = () => {
  return useMutation(createDataFeedSupplierAliasItem)
}

export const useUpdateDataFeedItemStatus = (options?: { onSettled?: () => void }) => {
  const { onSettled } = options || {}

  return useMutation(updateDataFeedItemStatus, {
    ...(onSettled ? { onSettled } : {}),
  })
}

export const useScoreDataRequestAnswers = () => {
  return useMutation(scoreDataRequestAnswers)
}

export const useDeleteDataRequestAnswerScores = () => {
  return useMutation(deleteDataRequestAnswerScores)
}

export const useUpdateDataRequestAnswerScores = () => {
  return useMutation(updateDataRequestAnswerScores)
}
