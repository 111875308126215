import { Tooltip, Avatar, Skeleton } from '@mui/material'
import {
  useGetRiskDataSource,
  useGetDataSource,
  useGetDataFeedItemsV2,
  useGetDataRequestQuestions,
} from 'hooks'
import { SendOutlined as DataRequestIcon, NewspaperOutlined as NewsIcon } from '@mui/icons-material'
import { RiskCategoryIcon } from 'components'
import config from 'constants/config'

interface Props {
  id?: string
  dataEntryId?: string
  type: string
  question?: string
  size?: number
  newsEvent?: string
}

const DataAvatar = ({ id, dataEntryId, type, question, size = 23, newsEvent }: Props) => {
  const { data: riskDataSourceData, error: riskDataSourceError } = useGetRiskDataSource(id || '', {
    enabled: Boolean(id && type === 'riskDataSource'),
  })

  const { data: dataSourceData, error: dataSourceError } = useGetDataSource(id || '', {
    enabled: Boolean(id && type === 'dataSource'),
  })
  const { data: dataFeedItemsData } = useGetDataFeedItemsV2(
    {
      dataEntryIds: dataEntryId ? [dataEntryId] : undefined,
    },
    {
      enabled: Boolean(
        (id && type === 'questionAnswer' && !question) || (type === 'newsEvent' && dataEntryId)
      ),
    }
  )
  const dataFeedItem = dataFeedItemsData?.pages[0].data?.dataRequestAnswers?.[0]

  const { data: dataRequestQuestionsData } = useGetDataRequestQuestions({
    pageSize: 1,
    ids: dataFeedItem?.dataRequestAnswers?.map((x) => x.dataRequestQuestionId) || [],
    enabled: Boolean(dataFeedItem?.dataRequestAnswers?.length && !question),
  })
  const dataRequestQuestion = dataRequestQuestionsData?.pages[0].data?.[0]

  const data = type === 'riskDataSource' ? riskDataSourceData : dataSourceData

  const isLoading =
    (type === 'riskDataSource' && !riskDataSourceData && !riskDataSourceError) ||
    (type === 'dataSource' && !dataSourceData && !dataSourceError)

  return (
    <Tooltip
      title={newsEvent || question || dataRequestQuestion?.definition.title || data?.name || ''}
    >
      <Avatar
        sx={{
          backgroundColor:
            (isLoading && 'transparent !important') ||
            (data && !data?.imgUrl && 'backgroundDark') ||
            'backgroundDark',
          border: (theme) => `1px solid ${theme.palette.common.white}`,
          width: size,
          height: size,
          boxShadow: 'none',
          marginLeft: '0px !important',
          marginRight: '-8px',
          cursor: 'pointer',
          transition: (theme) =>
            theme.transitions.create('opacity', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          '&:hover': {
            opacity: 0.7,
          },
        }}
        alt={newsEvent || data?.name || question || dataRequestQuestion?.definition.title || ''}
        src={
          data?.imgUrl
            ? `${config.largeMediaUrl}${data?.imgUrl}?nf_resize=fit&h=${size}`
            : undefined
        }
      >
        {isLoading && <Skeleton variant="circular" width={size} height={size} />}
        {Boolean(data && !data?.imgUrl && !question && type !== 'questionAnswer' && !newsEvent) && (
          <RiskCategoryIcon size={16} withBackground type="dataSource" />
        )}
        {Boolean(newsEvent) && <NewsIcon sx={{ color: 'common.black', width: 16, height: 16 }} />}
        {Boolean(type === 'questionAnswer') && (
          <DataRequestIcon sx={{ color: 'common.black', width: 16, height: 16 }} />
        )}
      </Avatar>
    </Tooltip>
  )
}

export default DataAvatar
