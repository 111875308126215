import { Tooltip, Avatar, Skeleton } from '@mui/material'
import {
  useGetDataEntry,
  useGetDataSource,
  useGetDataFeedItemsV2,
  useGetDataRequestQuestions,
} from 'hooks'
import { RiskCategoryIcon } from 'components'
import config from 'constants/config'
import { DataSourceType } from 'typescript-axios'

interface Props {
  id: string
  size?: number
  onClick?: () => void
}

const InlineDataSourceAvatar = ({ id, size = 23, onClick }: Props) => {
  const { data, error } = useGetDataEntry(id)

  const { data: dataSourceData, error: dataSourceError } = useGetDataSource(
    data?.data.dataSourceId || '',
    {
      enabled: Boolean(data?.data.dataSourceId),
    }
  )

  const shouldFetchDataFeedItems = Boolean(
    dataSourceData?.dataSourceType === DataSourceType.DataRequestAnswer ||
      dataSourceData?.dataSourceType === DataSourceType.News
  )
  const { data: dataFeedItemsData, error: dataFeedItemsError } = useGetDataFeedItemsV2(
    {
      dataEntryIds: [id],
      filter: {
        types: ['data_request_answer', 'adverse_media'],
      },
    },
    {
      enabled: shouldFetchDataFeedItems,
    }
  )
  const flattenedAnswerDataFeedItems = dataFeedItemsData?.pages.flatMap((x) => [
    ...(x.data.dataRequestAnswers || []),
  ])

  const flattenedNewsDataFeedItems = dataFeedItemsData?.pages.flatMap(
    (x) => x.data.adverseMedia || []
  )

  const questionIds = flattenedAnswerDataFeedItems?.[0]
    ? flattenedAnswerDataFeedItems[0].dataRequestAnswers.map((x) => x.dataRequestQuestionId)
    : []

  const newsEvent = flattenedNewsDataFeedItems?.find((x) => !x.article && x.childItemIds.length > 0)

  const { data: questionData } = useGetDataRequestQuestions({
    pageSize: 250,
    ids: questionIds || [],
    enabled: Boolean(questionIds && questionIds.length > 0),
  })
  const flattenedQuestionData = questionData?.pages.flatMap((x) => x.data)
  const question = flattenedQuestionData?.[0]

  const isLoading =
    (!dataSourceError && !error && !data && !dataSourceData) ||
    (shouldFetchDataFeedItems && !dataFeedItemsData && !dataFeedItemsError)

  const dataSourceName = isLoading ? '' : question?.definition.title || dataSourceData?.name

  return (
    <Tooltip
      title={
        (newsEvent && newsEvent.result.name) ||
        (question && question.definition.title) ||
        (dataSourceData?.name &&
          `${dataSourceData?.name}${
            data?.data.dataOriginName.toLowerCase() === 'claim' ? ' (claim)' : ''
          }`) ||
        ''
      }
    >
      <Avatar
        sx={[
          {
            backgroundColor:
              (isLoading && 'transparent !important') ||
              (dataSourceData && !dataSourceData?.imgUrl && 'transparent') ||
              'common.white',
            border: (theme) => `1px solid ${theme.palette.common.white}`,
            width: size,
            height: size,
            boxShadow: 'none',
            marginLeft: '-3px',
          },
          (theme) =>
            onClick
              ? {
                  cursor: 'pointer',
                  transition: theme.transitions.create('opacity', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                  '&:hover': {
                    opacity: 0.7,
                  },
                }
              : {},
        ]}
        alt={newsEvent?.result.name || dataSourceName || ''}
        src={
          dataSourceData?.imgUrl
            ? `${config.largeMediaUrl}${dataSourceData?.imgUrl}?nf_resize=fit&h=${size}`
            : undefined
        }
      >
        {isLoading && <Skeleton variant="circular" width={size} height={size} />}
        {data && dataSourceData && (
          <RiskCategoryIcon size={16} withBackground type={dataSourceData.dataSourceType} />
        )}
      </Avatar>
    </Tooltip>
  )
}

export default InlineDataSourceAvatar
