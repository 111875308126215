import { QueryOptions, UseQueryOptions, useQuery } from 'react-query'

import { AxiosError } from 'axios'
import { promptGetCleanedCompanyName, promptGetCountrySuggestion } from './api'

export const usePromptGetCleanedCompanyName = (
  companyName: string,
  options?: UseQueryOptions<Promise<string>, AxiosError, string, string[]>
) => {
  return useQuery(
    ['usePromptGetCleanedCompanyName', companyName],
    () => promptGetCleanedCompanyName(companyName),
    options
  )
}

export const usePromptGetCountrySuggestion = (
  companyName: string,
  options?: UseQueryOptions<Promise<string>, AxiosError, string, string[]>
) => {
  return useQuery(
    ['usePromptGetCountrySuggestion', companyName],
    () => promptGetCountrySuggestion(companyName),
    options
  )
}
