import { DataFeedItemsOptions } from 'services/mingle/__generated__/Api'

const dataSubscriptions = (supplierId?: string) => ['dataSubscriptions', supplierId] as const
const supplierDiscoveries = (searchValues: { supplierName: string; supplierCountry?: string }[]) =>
  ['supplierDiscoveries', searchValues] as const
const websiteDiscoveries = (searchValues: { supplierName: string; supplierCountry?: string }[]) =>
  ['websiteDiscoveries', searchValues] as const
const industryDiscoveries = (domains: string[]) => ['industryDiscoveries', domains] as const
const dataDiscoveries = (domains: string[]) => ['dataDiscoveries', domains] as const
const articleById = (articleId: string) => ['articleById', articleId] as const
const allDataFeedItems = ['allDataFeedItems'] as const
const dataFeedItems = (props?: DataFeedItemsOptions) =>
  [...allDataFeedItems, ...Object.values(props || {})] as const
const dataRequestAnswerEntriesFromRequest = (dataRequestId: string) =>
  ['dataRequestAnswerEntriesFromRequest', dataRequestId] as const
const dataRequestAnswerEntriesFromQuestion = (
  dataRequestQuestionId: string,
  organizationSupplierId?: string
) =>
  ['dataRequestAnswerEntriesFromQuestion', dataRequestQuestionId, organizationSupplierId] as const

export default {
  dataSubscriptions,
  supplierDiscoveries,
  websiteDiscoveries,
  industryDiscoveries,
  dataDiscoveries,
  articleById,
  dataFeedItems,
  dataRequestAnswerEntriesFromRequest,
  dataRequestAnswerEntriesFromQuestion,
  allDataFeedItems,
} as const
