import {
  RiskFilterIn,
  RiskContextRiskOverviewFilterIn,
  PolicyParameterRiskOverViewFilterIn,
} from 'typescript-axios'

const risk = ['risk'] as const
const riskDataEntriesPages = [...risk, 'riskDataEntriesPages'] as const
const riskDataEntriesPage = ({ ids, pageSize }: { ids: string[]; pageSize: number }) =>
  [...riskDataEntriesPages, ids, pageSize] as const
const supplierRiskPages = [...risk, 'supplierRiskPages'] as const
const supplierRiskPage = ({ supplierId }: { supplierId: string }) =>
  [...supplierRiskPages, supplierId] as const
const singleRiskDataSources = [...risk, 'singleRiskDataSources'] as const
const singleRiskDataSource = (id: string) => [...singleRiskDataSources, id] as const

const mitigationRulesPages = [...risk, 'mitigationRulesPages'] as const
const mitigationRulesPage = ({
  parameterIds,
  pageSize,
}: {
  parameterIds: string[]
  pageSize: number
}) => [...mitigationRulesPages, parameterIds, pageSize] as const
const singleMitigationRule = (parameterId: string) =>
  [...risk, 'singleMitigationRule', parameterId] as const
const riskObjective = [...risk, 'riskObjective'] as const
const riskGrouped = (filter: RiskFilterIn) => [...risk, 'riskWithFilters', filter] as const
const riskOverviewContext = (filter: RiskContextRiskOverviewFilterIn) =>
  [...risk, 'riskOverviewContext', filter] as const
const riskOverviewParameters = (
  filter: PolicyParameterRiskOverViewFilterIn,
  uniqueQueryKey?: string
) => [...risk, 'riskOverviewParameters', filter, uniqueQueryKey] as const

export default {
  risk,
  riskDataEntriesPages,
  riskDataEntriesPage,
  supplierRiskPages,
  supplierRiskPage,
  singleRiskDataSources,
  singleRiskDataSource,
  mitigationRulesPages,
  mitigationRulesPage,
  singleMitigationRule,
  riskObjective,
  riskGrouped,
  riskOverviewContext,
  riskOverviewParameters,
}
