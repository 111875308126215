import qs from 'qs'
import config from 'constants/config'
import { Api } from './__generated__/Api'

const client = new Api({
  baseURL: config.mingleBaseUrl,
  timeout: 50000,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
})

export const setTokenInterceptor = async (accessToken: string): Promise<void> => {
  client.instance.interceptors.request.use(
    (axiosConfig) => {
      Object.assign(axiosConfig, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return axiosConfig
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      return Promise.reject(error)
    }
  )
}

export default client
