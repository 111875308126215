import { DataEntryPolicyParameterValueV2ApiDto, DataEntryValueApiDto } from 'typescript-axios'

export default (dataEntryValues?: DataEntryValueApiDto[], parameterId?: string) => {
  if (!dataEntryValues) return undefined
  const scorableRawValues =
    dataEntryValues?.filter((y): y is DataEntryPolicyParameterValueV2ApiDto => {
      const isScorable = Boolean('score' in y && y.score && 'value' in y.score)
      const isForParameter =
        !parameterId || ('policyParameterId' in y && y.policyParameterId === parameterId)
      return isScorable && isForParameter
    }) || []
  const averageScore =
    scorableRawValues.length > 0
      ? scorableRawValues.reduce((prev, curr) => {
          if (curr.score === undefined) return prev
          return prev + curr.score.value
        }, 0) / scorableRawValues.length
      : undefined

  const ceil = averageScore !== undefined ? Math.ceil(averageScore * 100) / 100 : undefined

  return ceil
}
