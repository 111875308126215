import { useQueries, useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'
import {
  getDataDiscoveryRuns,
  getLastDataDiscoveryRun,
  getLastDataDiscoveryRunDataSetItems,
  getLastSupplierDiscoveryRunDataSetItems,
  runWebsiteDiscovery,
  WebsiteDiscoveryRun,
  runSingleDataDiscovery,
  testDataDiscovery,
  TestDataDiscoveryProps,
  industryDiscovery,
  IndustryDiscoveryProps,
  getFilesByOriginId,
  getAdminDataDiscoveryRuns,
} from './api'
import queryKeys from './queryKeys'

export const useGetFilesByOriginId = (originId: string) => {
  return useQuery(queryKeys.filesByOriginId(originId), () => getFilesByOriginId(originId))
}

export const useGetFilesByOriginIds = (originIds: string[], refetchInterval?: number) => {
  return useQueries(
    originIds.map((originId) => ({
      queryKey: queryKeys.filesByOriginId(originId),
      queryFn: () => getFilesByOriginId(originId),
      refetchInterval,
    }))
  )
}

export const useGetAdminDataDiscoveryRuns = (domains: string[], refetchInterval?: number) => {
  return useQueries(
    domains.map((domain) => ({
      queryKey: queryKeys.dataDiscoveryAdminRuns(domain),
      queryFn: () => getAdminDataDiscoveryRuns(domain),
      refetchInterval,
    }))
  )
}

export const useGetDataDiscoveryRuns = (accessToken: string) => {
  return useQuery(queryKeys.dataDiscoveryListRuns, () => getDataDiscoveryRuns(accessToken), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })
}

export const useGetLastDataDiscoveryRun = (accessToken: string) => {
  return useQuery(queryKeys.dataDiscoveryLastRun, () => getLastDataDiscoveryRun(accessToken), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })
}

export const useGetLastDataDiscoveryRunDataSetItem = (accessToken: string) => {
  return useQuery(
    queryKeys.dataDiscoveryLastRunDataSetItems,
    () => getLastDataDiscoveryRunDataSetItems(accessToken),
    { refetchOnWindowFocus: false, refetchOnMount: false, refetchOnReconnect: false }
  )
}

export const useGetLastSupplierDiscoveryRunDataSetItem = (accessToken: string) => {
  return useQuery(
    queryKeys.supplierDiscoveryLastRunDataSetItems,
    () => getLastSupplierDiscoveryRunDataSetItems(accessToken),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )
}

export const useRunWebsiteDiscovery = (
  supplierName: string,
  accessToken: string,
  options?: UseQueryOptions<
    WebsiteDiscoveryRun[],
    AxiosError,
    WebsiteDiscoveryRun[],
    ReturnType<typeof queryKeys.websiteDiscoveryRun>
  >
) => {
  return useQuery(
    queryKeys.websiteDiscoveryRun(supplierName),
    () => runWebsiteDiscovery(supplierName, accessToken),
    {
      ...options,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )
}

export const useRunSingleDataDiscovery = (
  website: string,
  accessToken: string,
  options?: UseQueryOptions<any[], AxiosError, any[], ReturnType<typeof queryKeys.dataDiscovery>>
) => {
  return useQuery(
    queryKeys.dataDiscovery(website),
    () => runSingleDataDiscovery(website, accessToken),
    {
      ...options,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )
}

export const useRunTestSingleDataDiscovery = (
  website: string,
  options?: UseQueryOptions<
    TestDataDiscoveryProps[],
    AxiosError,
    TestDataDiscoveryProps[],
    ReturnType<typeof queryKeys.testDataDiscovery>
  >
) => {
  return useQuery(queryKeys.testDataDiscovery(website), () => testDataDiscovery(website), {
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })
}

export const useRunIndustryDiscovery = (
  supplierName: string,
  supplierCountry: string,
  options?: UseQueryOptions<
    IndustryDiscoveryProps[],
    AxiosError,
    IndustryDiscoveryProps[],
    ReturnType<typeof queryKeys.industryDiscovery>
  >
) => {
  return useQuery(
    queryKeys.industryDiscovery(supplierName, supplierCountry),
    () => industryDiscovery(supplierName, supplierCountry),
    {
      ...options,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )
}
