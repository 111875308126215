import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query'
import { AxiosError } from 'axios'
import { DataFeedItemsOptionsV2 } from 'services/mingle/__generated__/Api'
import {
  getDataDiscoveries,
  getDataSubscriptions,
  getIndustryDiscoveries,
  getSupplierDiscoveries,
  getArticleById,
  getDataFeedItems,
  getWebsiteDiscoveries,
  getDataRequestAnswerEntriesFromRequest,
  getEntriesByQuestion,
} from './api'

import queryKeys from './queryKeys'

const { dataFeedItems, articleById, dataRequestAnswerEntriesFromRequest } = queryKeys

export const useGetArticleById = (
  articleId: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getArticleById>>,
    AxiosError,
    Awaited<ReturnType<typeof getArticleById>>,
    ReturnType<typeof articleById>
  >
) => {
  return useQuery(articleById(articleId), () => getArticleById({ articleId }), options)
}

export const useGetEntriesByQuestion = ({
  dataRequestQuestionId,
  organizationSupplierId,
  enabled,
}: {
  dataRequestQuestionId: string
  organizationSupplierId?: string
  enabled?: boolean
}) => {
  return useQuery(
    queryKeys.dataRequestAnswerEntriesFromQuestion(dataRequestQuestionId, organizationSupplierId),
    () => getEntriesByQuestion({ dataRequestQuestionId, organizationSupplierId }),
    {
      enabled,
    }
  )
}

export const defaultSupplierViewPayload: DataFeedItemsOptionsV2 = {
  pageSize: 100000,
  filter: {
    types: ['data_request_answer', 'adverse_media'],
  },
}

export const useGetDataFeedItemsV2 = (
  props?: DataFeedItemsOptionsV2,
  options?: {
    enabled?: boolean
    refetchInterval?: number
  }
) => {
  return useInfiniteQuery<
    Awaited<ReturnType<typeof getDataFeedItems>>,
    AxiosError,
    Awaited<ReturnType<typeof getDataFeedItems>>,
    ReturnType<typeof dataFeedItems>
  >(
    dataFeedItems(props),
    ({ pageParam = 1 }) =>
      getDataFeedItems({
        ...props,
        page: pageParam,
      }),
    {
      ...options,
      getNextPageParam: (lastPage) => {
        return props?.pageSize && lastPage.data.adverseMedia.length < props.pageSize
          ? undefined
          : (lastPage.page || 0) + 1
      },
    }
  )
}

export const useGetDataRequestAnswerEntriesFromRequest = (
  dataRequestId: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getDataRequestAnswerEntriesFromRequest>>,
    AxiosError,
    Awaited<ReturnType<typeof getDataRequestAnswerEntriesFromRequest>>,
    ReturnType<typeof dataRequestAnswerEntriesFromRequest>
  >
) => {
  return useQuery(
    dataRequestAnswerEntriesFromRequest(dataRequestId),
    () => getDataRequestAnswerEntriesFromRequest({ dataRequestId }),
    options
  )
}

export const useGetDataSubscriptions = (organizationSupplierId: string, enabled?: boolean) => {
  return useQuery(
    queryKeys.dataSubscriptions(organizationSupplierId),
    () => getDataSubscriptions(organizationSupplierId),
    {
      retry: true,
      staleTime: 500000,
      enabled,
    }
  )
}

export const useGetSupplierDiscoveries = (
  searchValues: { supplierName: string; supplierCountry?: string }[],
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  return useQuery(
    queryKeys.supplierDiscoveries(searchValues),
    () => getSupplierDiscoveries(searchValues),
    {
      ...options,
      retry: true,
      staleTime: 500000,
    }
  )
}

export const useGetWebsiteDiscoveries = (
  searchValues: { supplierName: string; supplierCountry?: string }[],
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  return useQuery(
    queryKeys.websiteDiscoveries(searchValues),
    () => getWebsiteDiscoveries(searchValues),
    {
      ...options,
      retry: true,
      staleTime: 500000,
    }
  )
}

export const useGetIndustryDiscoveries = (
  domains: string[],
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  return useQuery(queryKeys.industryDiscoveries(domains), () => getIndustryDiscoveries(domains), {
    ...options,
    retry: true,
    staleTime: 500000,
  })
}

export const useGetDataDiscoveries = (domains: string[], enabled?: boolean) => {
  return useQuery(queryKeys.dataDiscoveries(domains), () => getDataDiscoveries(domains), {
    retry: true,
    staleTime: 500000,
    enabled,
  })
}
