import { tokenAxiosInstance } from 'services/apiClient'

const baseURL = '/.netlify/functions'

export const promptGetCleanedCompanyName = async (companyName: string): Promise<string> => {
  const { data: response } = await tokenAxiosInstance.post(
    '/promptGetCleanedCompanyName',
    { companyName },
    { baseURL }
  )

  return response
}

export const promptGetCountrySuggestion = async (companyName: string): Promise<string> => {
  const { data: response } = await tokenAxiosInstance.post(
    '/promptGetCountrySuggestion',
    { companyName },
    { baseURL }
  )

  return response
}
