import { lazy } from 'react'
import {
  DashboardRounded as DashboardIcon,
  SettingsEthernetRounded as IntegrationsIcon,
  BusinessRounded as SuppliersIcon,
  BubbleChartRounded as DataSourceIcon,
  SettingsRounded as SettingsIcon,
  SendRounded as DataRequestsIcon,
  DescriptionRounded as FilesIcon,
  TrackChangesRounded as ObjectivesIcon,
  WarningRounded as RiskIcon,
} from '@mui/icons-material'
import { Cards as ScorecardsIcon } from 'mdi-material-ui'
import { AuthenticatedRouteDef } from '../AuthenticatedRoute'

const Dashboard = lazy(() => import('views/buyer/Dashboard'))
const Suppliers = lazy(() => import('views/buyer/Suppliers'))
const Scorecards = lazy(() => import('views/buyer/Scorecards'))
const DataRequests = lazy(() => import('views/buyer/DataRequests'))
const DataSources = lazy(() => import('views/buyer/DataSources'))
const Integrations = lazy(() => import('views/buyer/Integrations'))
const Settings = lazy(() => import('views/shared/Settings'))
const Objectives = lazy(() => import('views/buyer/Objectives'))
const Files = lazy(() => import('views/buyer/Files'))
const Risk = lazy(() => import('views/buyer/Risk'))
const NewMagic = lazy(() => import('views/demo/NewMagic'))

export const buyerPortalBasePath = '/'
export const responsiblyOrgId = '01f4y1xvabqw75w4wa95hgwstc'
export const responsiblyOtherDemoOrgId = '01gkgyht1ejjf8j8tq0tkh48md'
export const responsiblyDemoOrgId = '01fzt7gjqpb1wcysjkga6c34mg'
export const quinnSnacksOrgId = '01fn7a3py75zktjmepyrhjpgk7'
export const xindaoOrgId = '01fqbt2sjk1fc1sabj0qfgpms1'
export const yamoOrgId = '01fne47xngssw8ht5np5w6xvxc'
export const spinOrgId = '01fpj4553bsr8fvp72ewgydbjs'
export const ecoalfOrgId = '01fwnmzx8axh0kmxe4096qgpff'
export const lantmannenUnibakeOrgId = '01fx34g6z1wkgdtpyaj6zmtdvj'
export const trilinkOrgId = '01fs1qzrp0pdw2fs3aq5833dhv'
export const collectivFoodId = '01g1p9sy4tn9nx396bh9d52jmb'
export const cernId = '01fzfyv2bm2mk4ghttkajrkxv7'
export const noieOrgId = '01fzmxgx4366wj9npczy1n3wft'
export const colpacId = '01fz7wwrryw79aamwnev4afhpd'
export const intersteelId = '01g5499kwbp1jcpwpczs928ah1'
export const setlogId = '01gvdpbx1yxndtez0hs8yb7bcz'
export const covaloOrgId = '01gq7pbr4mg81cxbs7621h99sq'
export const maerskOrgId = '01hsjkb2wgfb3rt0he5pdvzma6'

const buyerRoutes: AuthenticatedRouteDef[] = [
  {
    routeKey: 'ViewOverview',
    title: 'Overview',
    rootPath: '/overview',
    path: `/overview`,
    requiresOneOf: [],
    component: Dashboard,
    withLayout: true,
    icon: DashboardIcon,
    menuPosition: 'top',
  },
  {
    routeKey: 'ViewSuppliers',
    title: 'Suppliers',
    rootPath: '/suppliers',
    path: `/suppliers/:supplier?/:item?/:entityId?/:entityTab?/:childEntity?/:childEntityTab?/:fileId?/:action?`,
    requiresOneOf: [],
    component: Suppliers,
    withLayout: true,
    icon: SuppliersIcon,
    menuPosition: 'top',
  },
  {
    routeKey: 'ViewScorecards',
    title: 'Scorecards',
    rootPath: '/scorecards',
    path: `/scorecards/:id?/:page?/:1?/:2?/:3?/:4?/:5?/:6?/:7?/:8?`,
    requiresOneOf: [],
    component: Scorecards,
    withLayout: true,
    icon: ScorecardsIcon,
    menuPosition: 'top',
  },
  {
    routeKey: 'ViewRisk',
    title: 'Risk',
    rootPath: '/risk',
    path: `/risk/:tab?`,
    requiresOneOf: [],
    component: Risk,
    withLayout: true,
    icon: RiskIcon,
    menuPosition: 'top',
  },
  {
    routeKey: 'ViewDataSources',
    title: 'Data_Sources',
    rootPath: '/data-sources',
    path: '/data-sources/:id?/:entityId?/:page?/:action?/:anotherAction?/:thirdAction?/:fourthAction?',
    requiresOneOf: [],
    component: DataSources,
    withLayout: true,
    icon: DataSourceIcon,
    menuPosition: 'top',
  },
  {
    routeKey: 'ViewDataRequests',
    title: 'Data_requests',
    rootPath: '/data-requests',
    path: '/data-requests/:id?/:action?/:anotherAction?/:thirdAction?',
    requiresOneOf: [],
    component: DataRequests,
    withLayout: true,
    icon: DataRequestsIcon,
    menuPosition: 'top',
  },
  {
    routeKey: 'ViewObjectives',
    title: 'Objectives',
    rootPath: '/objectives',
    path: '/objectives/:tab?/:action?',
    requiresOneOf: [],
    component: Objectives,
    withLayout: true,
    icon: ObjectivesIcon,
    menuPosition: 'top',
  },
  {
    routeKey: 'ViewFiles',
    title: 'Files',
    rootPath: '/files',
    path: '/files/:file?/:action?',
    requiresOneOf: [],
    component: Files,
    withLayout: true,
    icon: FilesIcon,
    menuPosition: 'top',
  },
  {
    routeKey: 'ViewIntegrations',
    title: 'Integrations',
    rootPath: '/integrations',
    path: `/integrations/:id?`,
    requiresOneOf: [],
    component: Integrations,
    withLayout: true,
    icon: IntegrationsIcon,
    menuPosition: 'bottom',
  },
  {
    routeKey: 'ViewSettings',
    title: 'Settings',
    rootPath: '/settings',
    path: `/settings/:setting?/:action?`,
    requiresOneOf: [],
    component: Settings,
    withLayout: true,
    icon: SettingsIcon,
    menuPosition: 'bottom',
  },
  {
    routeKey: 'ViewMagicDemo',
    title: 'Magic Demo',
    rootPath: '/magic',
    path: `/magic/:supplierId?`,
    requiresOneOf: [],
    component: NewMagic,
    withLayout: false,
    icon: SettingsIcon,
  },
]

export default buyerRoutes
