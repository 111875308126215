import { createRoot } from 'react-dom/client'
import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import Auth0ProviderWithHistory from 'auth/Auth0ProviderWithHistory'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import queryClient from 'services/queryClient'
import reportWebVitals, { sendWebVitalsToMixpanel } from 'services/reportWebVitals'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import theme from './theme'
import './i18n'
import App from './App'

const cache = createCache({
  key: 'css',
  prepend: true,
})

export const ResponsiblyApp = () => {
  return (
    <CacheProvider value={cache}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Auth0ProviderWithHistory>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
                <App />
              </QueryClientProvider>
            </Auth0ProviderWithHistory>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </CacheProvider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<ResponsiblyApp />)

// https://cra.link/PWA
serviceWorkerRegistration.register()

reportWebVitals(sendWebVitalsToMixpanel)
