import { InputAdornment, Autocomplete, AutocompleteProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TextField } from 'components'

const PREFIX = 'ButtonSelect'

const classes = {
  root: `${PREFIX}-root`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputLabel: `${PREFIX}-inputLabel`,
  inputAdornment: `${PREFIX}-inputAdornment`,
  inputLabelFormControl: `${PREFIX}-inputLabelFormControl`,
  autoCompleteInput: `${PREFIX}-autoCompleteInput`,
  startAdornment: `${PREFIX}-startAdornment`,
  inputContainer: `${PREFIX}-inputContainer`,
  inputLabelShrink: `${PREFIX}-inputLabelShrink`,
  popupIndicator: `${PREFIX}-popupIndicator`,
  clearIndicator: `${PREFIX}-clearIndicator`,
  popper: `${PREFIX}-popper`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: 'fit-content',
  },

  [`& .${classes.inputRoot}`]: {
    borderRadius: `${theme.shape.borderRadius} !important`,
  },

  [`& .${classes.inputLabel}`]: {
    fontSize: '.7525rem',
    fontWeight: 600,
    color: '#b5b5b5',
  },

  [`& .${classes.inputAdornment}`]: {
    marginLeft: theme.spacing(0.5),
  },

  [`& .${classes.inputLabelFormControl}`]: {
    left: 22,
    top: 0,
  },

  [`& .${classes.autoCompleteInput}`]: {
    paddingLeft: 0,
  },

  [`& .${classes.startAdornment}`]: {
    position: 'absolute',
    left: 10,
    top: 18,
    zIndex: 20,
    '& svg': {
      width: 20,
      height: 20,
    },
  },

  [`&.${classes.inputContainer}`]: {
    position: 'relative',
  },

  [`& .${classes.inputLabelShrink}`]: {
    transform: 'translate(-10px, -6px) scale(1) !important',
    fontSize: '.6225rem',
    color: theme.palette.text.primary,
  },

  [`& .${classes.popupIndicator}`]: {
    marginTop: 3,
    marginRight: -4,
    '& svg': {
      width: 18,
      height: 18,
    },
  },

  [`& .${classes.clearIndicator}`]: {
    padding: 2,
    marginTop: 3,
    '& svg': {
      width: 18,
      height: 18,
    },
  },

  [`& .${classes.popper}`]: {
    width: 'fit-content !important',
    // TODO CALC MIN WIDTH BASED ON INPUT LENGTH
    minWidth: 'fit-content !important',
    left: 0,
  },
}))

interface Props<T>
  extends Omit<AutocompleteProps<T, false, undefined | boolean, false>, 'renderInput'> {
  label?: string
  placeholder?: string
  startAdornmentIcon?: React.ReactNode
  /** used to scale input width in ch units. Leave undefined to use label */
  valueLength?: number
  autoFocus?: boolean
  popperFitContentWidth?: boolean
}

const ButtonSelect = <T extends object | string>({
  // object
  label,
  placeholder,
  startAdornmentIcon,
  value,
  valueLength,
  autoFocus,
  fullWidth = false,
  popperFitContentWidth,
  ...rest
}: Props<T>) => {
  const inputWidthCh =
    (valueLength && valueLength + 2) ||
    (label && label.length) ||
    (placeholder && placeholder.length) ||
    10

  return (
    <Autocomplete
      {...rest}
      value={value || null}
      fullWidth={fullWidth}
      className={fullWidth ? '' : classes.root}
      openOnFocus
      blurOnSelect
      classes={{
        input: classes.autoCompleteInput,
        popupIndicator: classes.popupIndicator,
        clearIndicator: classes.clearIndicator,
        popper: fullWidth ? classes.popper : classes.popper,
      }}
      renderInput={(params) => {
        return (
          <Root className={classes.inputContainer}>
            {/* can't use startAdornment on TextField because startAdornment is used for label. Hacking for now */}
            {startAdornmentIcon && (
              <InputAdornment position="start" className={classes.startAdornment}>
                {startAdornmentIcon}
              </InputAdornment>
            )}
            <TextField
              {...params}
              size="small"
              autoFocus={autoFocus}
              label={label}
              withMargin={false}
              placeholder={placeholder}
              fullWidth={fullWidth}
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: classes.inputRoot,
                },
                style: {
                  // width: fullWidth ? '100%' : `${inputWidthCh + 10}ch`,
                  borderRadius: 10,
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingRight: 38,
                  paddingLeft: startAdornmentIcon ? 28 : 6,
                  fontSize: '.7525rem',
                  height: 36,
                },
              }}
              InputLabelProps={{
                className: classes.inputLabel,
                classes: {
                  formControl: classes.inputLabelFormControl,
                  shrink: classes.inputLabelShrink,
                },
              }}
            />
          </Root>
        )
      }}
    />
  )
}

export default ButtonSelect
