import {
  Grid,
  ListItem,
  ListItemText,
  ListItemAvatar,
  SxProps,
  Theme,
  Skeleton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Donut } from 'components'
import { useTranslation } from 'react-i18next'

const PREFIX = 'Cards'

const classes = {
  performanceCard: `${PREFIX}-performanceCard`,
  dataCoverageCard: `${PREFIX}-dataCoverageCard`,
  content: `${PREFIX}-content`,
  progressText: `${PREFIX}-progressText`,
  title: `${PREFIX}-title`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.performanceCard}`]: {
    border: `1px solid ${theme.palette.background.default}`,
  },

  [`& .${classes.dataCoverageCard}`]: {
    border: `1px solid ${theme.palette.background.default}`,
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(1.5),
  },

  [`& .${classes.progressText}`]: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },

  [`& .${classes.title}`]: {
    fontWeight: 600,
    marginTop: theme.spacing(),
  },
}))

interface Props {
  score?: number
  itemsAmount?: number
  sx?: SxProps<Theme>
  parametersOnly?: boolean
  loading?: boolean
  children?: React.ReactNode
}

const Cards = ({ score, itemsAmount, parametersOnly, sx, loading, children }: Props) => {
  const { t } = useTranslation(['scorecards'])

  return (
    <StyledGrid container spacing={2} sx={{ marginTop: '2px', marginBottom: 1, ...sx }}>
      <Grid item xs={12}>
        <ListItem className={classes.performanceCard} style={{ cursor: 'default' }}>
          <ListItemAvatar style={{ width: 55, minWidth: 65, marginRight: 10 }}>
            {loading ? (
              <Skeleton variant="circular" width={50} height={50} />
            ) : (
              <Donut
                value={score}
                typographyVariant="body1"
                size={65}
                gaugeMarginTop={-15}
                centerTextSx={{
                  pt: '21px',
                }}
              />
            )}
          </ListItemAvatar>
          <ListItemText
            primary={
              loading ? (
                <Skeleton variant="text" width={50} />
              ) : (
                <strong>{t('Overall_Performance')}</strong>
              )
            }
            secondary={
              itemsAmount !== undefined
                ? `${
                    parametersOnly
                      ? t('Overall_Performance_AcrossParametersWithCount', { count: itemsAmount })
                      : t('Overall_Performance_AcrossItemsWithCount', { count: itemsAmount })
                  }`
                : ''
            }
          />
          {children}
        </ListItem>
      </Grid>
    </StyledGrid>
  )
}

export default Cards
