import { useEffect } from 'react'
import { useGetSettings } from 'hooks'
import config from 'constants/config'
import {
  Typography,
  alpha,
  Grid,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  SxProps,
  Theme,
} from '@mui/material'
import { FileWithPath, useDropzone } from 'react-dropzone'
import {
  GetAppRounded as DropIcon,
  InfoOutlined as InfoIcon,
  FileUploadOutlined as FileUploadIcon,
} from '@mui/icons-material'

interface Props {
  handleAddFiles: (files: readonly FileWithPath[]) => void
  children?: React.ReactNode
  showTocNotice?: boolean
  /** e.g. Drop supplier CSV/Excel here */
  dropText: string
  disabled?: boolean
  sx?: SxProps<Theme>
}

const Dropzone = ({
  handleAddFiles,
  children,
  showTocNotice = true,
  dropText,
  disabled,
  sx,
}: Props) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    disabled,
  })

  useEffect(() => {
    if (acceptedFiles.length > 0) handleAddFiles(acceptedFiles)
  }, [acceptedFiles])

  const handleOpenTermsAndConditions = () => {
    window.open(config.termsAndConditionsUrl, '_blank')
  }

  const { data } = useGetSettings({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: showTocNotice,
  })

  return (
    <Grid container sx={{ ...sx }}>
      <Grid item xs={12}>
        <Box
          {...getRootProps()}
          sx={{
            opacity: disabled ? 0.4 : 1,
            width: '100%',
            paddingTop: 2,
            paddingBottom: 2,
            border: (theme) =>
              `2px dashed ${
                isDragActive
                  ? alpha(theme.palette.primary.main, 0.8)
                  : alpha(theme.palette.primary.main, 0.45)
              }`,
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: disabled ? 'default' : 'pointer',
            transition: (theme) =>
              theme.transitions.create(['background-color', 'border-color', 'border'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            color: (theme) => (isDragActive ? theme.palette.primary.main : 'text.primary'),
            backgroundColor: (theme) => alpha(theme.palette.background.default, 0.25),
            '&:hover': {
              backgroundColor: (theme) => alpha(theme.palette.background.default, 0.35),
              borderColor: (theme) => alpha(theme.palette.primary.main, 0.45),
            },
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <DropIcon
              sx={{
                color: 'inherit',
                transition: (theme) =>
                  theme.transitions.create('color', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.shortest,
                  }),
                width: 20,
                height: 20,
                mb: 0.75,
              }}
            />
          ) : (
            <FileUploadIcon
              sx={{
                color: 'inherit',
                transition: (theme) =>
                  theme.transitions.create('color', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.shortest,
                  }),
                width: 20,
                height: 20,
                mb: 0.75,
              }}
            />
          )}
          <Typography
            variant="body2"
            sx={{
              color: 'inherit',
              transition: (theme) =>
                theme.transitions.create('color', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.shortest,
                }),
            }}
          >
            <strong>{isDragActive ? 'Drop file here' : dropText}</strong>
          </Typography>
        </Box>
      </Grid>
      {children && (
        <Grid item xs={12} style={{ marginBottom: 16 }}>
          {children}
        </Grid>
      )}
      {showTocNotice && (
        <Grid item xs={12}>
          <ListItem alignItems="flex-start" style={{ padding: 0 }}>
            <ListItemIcon style={{ marginTop: 2 }}>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              secondary={
                <Typography variant="body2" color="textSecondary">
                  <strong>Your data will remain yours.</strong> Responsibly has strict
                  confidentiality rules and will use {data?.data.name || 'your organization'}&apos;s
                  data solely for the purpose of delivering Responsibly&apos;s services. Learn more
                  in the{' '}
                  <Typography
                    variant="body2"
                    color="primary"
                    component="span"
                    display="inline"
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                        opacity: 0.7,
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={handleOpenTermsAndConditions}
                  >
                    terms and conditions
                  </Typography>
                  .
                </Typography>
              }
            />
          </ListItem>
        </Grid>
      )}
    </Grid>
  )
}

export default Dropzone
