import { alpha, Avatar, Tooltip, Box, SxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  AccessibilityNewRounded as HumanRightsIcon,
  AttachMoneyRounded as OperationsIcon,
  FindInPageRounded as AuditReportIcon,
  DescriptionRounded as DocumentIcon,
  ScatterPlotRounded as DataPointIcon,
  BubbleChartRounded as DataSourceIcon,
  SettingsRounded as SettingsIcon,
  BusinessRounded as SupplierIcon,
  AttachMoneyRounded as MoneyIcon,
  SendOutlined as SendIcon,
  NewspaperOutlined as NewsIcon,
} from '@mui/icons-material'
import {
  FileDocumentEdit as QuestionnaireIcon,
  FileCertificate as CertificateIcon,
  CheckDecagram as QualityIcon,
  Leaf as SustainabilityIcon,
} from 'mdi-material-ui'

const PREFIX = 'RiskCategoryIcon'

const classes = {
  sustainability: `${PREFIX}-sustainability`,
  quality: `${PREFIX}-quality`,
  qualityColor: `${PREFIX}-qualityColor`,
  diversity: `${PREFIX}-diversity`,
  humanRights: `${PREFIX}-humanRights`,
  supplierManagement: `${PREFIX}-supplierManagement`,
  operationss: `${PREFIX}-operationss`,
  environmental_impact: `${PREFIX}-environmental_impact`,
  social_impact: `${PREFIX}-social_impact`,
  supplier_management: `${PREFIX}-supplier_management`,
  operations: `${PREFIX}-operations`,
  responsibly: `${PREFIX}-responsibly`,
  background: `${PREFIX}-background`,
  bg: `${PREFIX}-bg`,
  other: `${PREFIX}-other`,
  data_source: `${PREFIX}-data_source`,
  dataSourceBg: `${PREFIX}-dataSourceBg`,
  tooltipHover: `${PREFIX}-tooltipHover`,
  spend: `${PREFIX}-spend`,
  spendBg: `${PREFIX}-spendBg`,
  dataRequest: `${PREFIX}-dataRequest`,
  dataRequestBg: `${PREFIX}-dataRequestBg`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.sustainability}`]: {
    color: theme.palette.policy.sustainability,
  },

  [`& .${classes.quality}`]: {
    backgroundColor: '#fff3c8',
  },

  [`& .${classes.qualityColor}`]: {
    color: '#e9b100',
  },

  [`& .${classes.diversity}`]: {
    color: theme.palette.policy.diversity,
  },

  [`& .${classes.humanRights}`]: {
    color: theme.palette.policy.humanRights,
  },

  [`& .${classes.supplierManagement}`]: {
    color: '#3eaae4',
  },

  [`& .${classes.operationss}`]: {
    color: theme.palette.policy.operations,
  },

  [`& .${classes.environmental_impact}`]: {
    backgroundColor: alpha(theme.palette.policy.sustainability || '#000', 0.14),
  },

  [`& .${classes.social_impact}`]: {
    backgroundColor: alpha(theme.palette.policy.humanRights || '#000', 0.07),
  },

  [`& .${classes.supplier_management}`]: {
    backgroundColor: '#39b5ff26',
  },

  [`& .${classes.operations}`]: {
    backgroundColor: alpha(theme.palette.policy.operations || '#000', 0.07),
  },

  [`& .${classes.responsibly}`]: {
    backgroundColor: alpha(theme.palette.primary.main || '#000', 0.07),
  },

  [`& .${classes.background}`]: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.bg}`]: {
    backgroundColor: theme.palette.backgroundDark,
  },

  [`& .${classes.other}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${classes.data_source}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${classes.dataSourceBg}`]: {
    backgroundColor: theme.palette.backgroundDark,
  },

  [`& .${classes.tooltipHover}`]: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  [`& .${classes.spend}`]: {
    color: theme.palette.info.main,
  },
  [`& .${classes.spendBg}`]: {
    backgroundColor: alpha(theme.palette.info.main || '#000', 0.15),
  },
  [`& .${classes.dataRequest}`]: {
    color: theme.palette.text.primary,
  },
  [`& .${classes.dataRequestBg}`]: {
    backgroundColor: theme.palette.backgroundDark,
  },
}))

interface Props {
  size: number
  type: string
  withBackground?: boolean
  tooltip?: string
  onClick?: () => void
  opacity?: number
  sx?: SxProps
}

// This is horrible - let's get rid of it asap (will never happen)
const RiskCategoryIcon = ({ size, type, withBackground, tooltip, onClick, opacity, sx }: Props) => {
  const sharedProps = {
    style: { width: size, height: size },
  }

  let icon = <DataSourceIcon className={classes.data_source} {...sharedProps} />

  if (type === 'newsEvent' || type === 'News') {
    icon = <NewsIcon className={classes.dataRequest} {...sharedProps} />
  }

  if (type === 'spend') {
    icon = <MoneyIcon className={classes.spend} {...sharedProps} />
  }

  if (type === 'dataRequest' || type === 'DataRequestAnswer') {
    icon = <SendIcon className={classes.dataRequest} {...sharedProps} />
  }

  if (
    type === 'environmental_impact' ||
    type === 'Environmental impact' ||
    type === 'environmentalImpact'
  ) {
    icon = <SustainabilityIcon className={classes.sustainability} {...sharedProps} />
  }

  if (type === 'social_impact' || type === 'Social impact' || type === 'socialImpact') {
    icon = <HumanRightsIcon className={classes.humanRights} {...sharedProps} />
  }

  /* if (type === 'diveristy') {
    return <DiversityIcon className={classes.diversity} {...sharedProps} />
  } */

  if (
    type === 'supplier_management' ||
    type === 'Supplier management' ||
    type === 'supplierManagement' ||
    type === 'supplier'
  ) {
    icon = <SupplierIcon className={classes.supplierManagement} {...sharedProps} />
  }

  if (type === 'supplier') {
    icon = <SupplierIcon {...sharedProps} />
  }

  if (type === 'operations') {
    icon = <OperationsIcon className={classes.operationss} {...sharedProps} />
  }

  if (type === 'questionnaire' || type === 'Questionnaire') {
    icon = <QuestionnaireIcon className={classes.other} {...sharedProps} />
  }

  if (type === 'dataPoint') {
    icon = <DataPointIcon className={classes.other} {...sharedProps} />
  }

  if (type === 'auditReport') {
    icon = <AuditReportIcon className={classes.other} {...sharedProps} />
  }

  if (type === 'certificate' || type === 'Certificate') {
    icon = <CertificateIcon className={classes.other} {...sharedProps} />
  }

  if (type === 'document') {
    icon = <DocumentIcon className={classes.other} {...sharedProps} />
  }

  if (type === 'dataSource' || type === 'data_source' || type === 'custom' || type === 'Custom') {
    icon = <DataSourceIcon className={classes.data_source} {...sharedProps} />
  }

  if (type === 'internal_field') {
    icon = <SettingsIcon className={classes.other} {...sharedProps} />
  }
  // yesyes, case
  if (type === 'Quality' || type === 'quality') {
    icon = <QualityIcon className={classes.qualityColor} {...sharedProps} />
  }

  return withBackground ? (
    <StyledBox>
      <Tooltip title={tooltip || ''}>
        <Avatar
          sx={{ ...sx }}
          onClick={onClick}
          className={`${onClick || tooltip ? classes.tooltipHover : ''} ${classes.background} ${
            (type === 'spend' && classes.spendBg) ||
            (type === 'dataRequest' && classes.dataRequestBg) ||
            ((type === 'environmental_impact' ||
              type === 'Environmental impact' ||
              type === 'environmentalImpact') &&
              classes.environmental_impact) ||
            ((type === 'social_impact' || type === 'Social impact' || type === 'socialImpact') &&
              classes.social_impact) ||
            (type === 'operations' && classes.operations) ||
            ((type === 'supplier_management' ||
              type === 'Supplier management' ||
              type === 'supplierManagement') &&
              classes.supplier_management) ||
            ((type === 'dataSource' || type === 'custom' || type === 'Custom') &&
              classes.dataSourceBg) ||
            ((type === 'Quality' || type === 'quality') && classes.quality) ||
            classes.bg
          }`}
          style={{ width: size * 1.7, height: size * 1.7, opacity }}
        >
          {icon}
        </Avatar>
      </Tooltip>
    </StyledBox>
  ) : (
    <StyledBox display="flex" sx={{ ...sx }}>
      {icon}
    </StyledBox>
  )
}

export default RiskCategoryIcon
